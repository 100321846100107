import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import Stack from '@mui/material/Stack';
import { withStyles } from '@mui/styles';
import { Typography } from '@mui/material/';

import styles from './styles';
import data from '../../utils/data';

class B13ca extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b13ca;
    this.imgBase = `${this.props.baseUrl}/blocks/`;
  }

  renderText(text, key) {
    const { classes } = this.props;
    return (
      <div key={key} className={classes.chapter}>
        {text.title && (
          <Typography variant="subtitle2" className={classes.textTitle}>
            {parse(text.title)}
          </Typography>
        )}
        {text.text && (
          <Typography variant="body2" className={`${classes.text} text`}>
            {parse(text.text)}
          </Typography>
        )}
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Stack direction="column" spacing={4} className={classes.blockContainer}>
        <Stack direction="column" alignItems="center" className={classes.bottom}>
          <Typography variant="h4" sx={{ p: '32px', textAlign: 'center' }} className={classes.subtitle}>
            {parse(this.dataBlock.subtitle)}
          </Typography>
          <Typography variant="body2" sx={{ p: '32px', textAlign: 'center' }} className={classes.intro}>
            {parse(this.dataBlock.intro)}
          </Typography>
          <Stack direction={{ xs: 'column', lg: 'row' }} sx={{ p: '32px' }} spacing={{ xs: '0', lg: '8' }}>
            <Stack direction="column" className={classes.column}>
              {this.dataBlock.desc1.map((desc, key) => this.renderText(desc, key))}
            </Stack>
            <Stack direction="column" className={classes.column}>
              {this.dataBlock.desc2.map((desc, key) => this.renderText(desc, key))}
            </Stack>
          </Stack>
          <img className={classes.banner} src={`${this.imgBase}/${this.dataBlock.banner}`} alt=""></img>
        </Stack>
      </Stack>
    );
  }
}

B13ca.propTypes = {
  baseUrl: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(B13ca);

export default theme => ({
  blockContainer: {
    backgroundColor: theme.palette.white.main,
    padding: `${theme.spacing(9)} calc(8vw) ${theme.spacing(3)} ${theme.spacing(7)}`,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
  },
  subtitle: {
    fontWeight: '500 !important',
    color: theme.palette.gold.main,
    paddingBottom: theme.spacing(4),
    width: '80%',
    maxWidth: 800,
    margin: 'auto !important',
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      textAlign: 'center',
      margin: '0 !important',
      width: '100%',
    },
  },
  bottom: {
    backgroundColor: theme.palette.beige.main,
  },
  column: {
    width: '100%',
  },
  banner: {
    width: '100%',
    objectFit: 'cover',
  },
  textTitle: {
    maxWidth: 350,
    fontWeight: 'bold !important',
    paddingBottom: theme.spacing(2),
    color: theme.palette.green.main,
  },
  text: {
    fontWeight: '300 !important',
    paddingBottom: theme.spacing(3),
    '& b': {
      color: theme.palette.gold.main,
    },
  },
  intro: {
    textAlign: 'left !important',
    paddingBottom: '0! important',
  },
});

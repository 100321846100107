export default theme => ({
  blockContainer: {
    position: 'relative',
    height: '100vh',
    width: '75vw',
    display: 'flex',
  },
  center: {
    width: '50vw',
  },
  right: {
    width: '25vw',
  },
  contentRight: {
    width: 'calc(25vw - 6vh - 1px)',
  },
  gutter: {
    width: '6vw',
  },
  top: {
    position: 'relative',
    minHeight: 25,
    maxHeight: '3vh',
    flex: 1,
  },
  mainMargin: {
    position: 'relative',
    height: 50,
  },
  main: {
    position: 'relative',
    height: 'calc(50vw - 12vh)',
    overflow: 'hidden',
    flex: 1,
    borderBottom: `solid 2px ${theme.palette.gold.main}`,
  },
  medium: {
    position: 'relative',
    height: 50,
    flexBasis: 50,
    flexShrink: 0,
    background: theme.palette.green.main,
    '&.center': {
      borderRight: `solid 2px ${theme.palette.gold.main}`,
    },
  },
  footer: {
    position: 'relative',
    minHeight: 25,
    maxHeight: '3vh',
    flex: 1,
    background: theme.palette.green.main,
    '&.center': {
      borderRight: `solid 2px ${theme.palette.gold.main}`,
    },
  },
  title: {
    fontSize: '0.8rem !important',
    fontWeight: '500 !important',
    color: theme.palette.gold.main,
  },
  text: {
    fontWeight: '300 !important',
    color: theme.palette.gold.main,
  },
  line: {
    position: 'absolute',
    zIndex: 1,
    bottom: -0,
    left: 'calc(50% + 1px)',
    height: '100%',
    width: 2,
    background: theme.palette.gold.main,
    '&.half': {
      height: '50%',
    },
  },
  lineMiddle: {
    position: 'absolute',
    top: '50%',
    left: 0,
    zIndex: 1,
    height: 2,
    width: '100%',
    background: theme.palette.gold.main,
  },
  topMain: {
    height: '50%',
  },
  bottomMain: {
    height: '50%',
    backgroundColor: theme.palette.green.main,
  },
  rightMainBottom: {
    height: '50%',
    backgroundColor: theme.palette.green.main,
    borderTop: `solid 2px ${theme.palette.gold.main}`,
    paddingBottom: theme.spacing(4),
  },
  rightMain: {
    height: '100%',
  },
  leftMedium: {
    width: '50%',
    borderRight: `solid 2px ${theme.palette.gold.main}`,
    paddingLeft: theme.spacing(10),
  },
  rightMedium: {
    width: '50%',
  },
  leftBottomMain: {
    width: '50%',
    borderTop: `solid 2px ${theme.palette.gold.main}`,
    borderRight: `solid 2px ${theme.palette.gold.main}`,
    paddingLeft: theme.spacing(10),
    paddingTop: theme.spacing(4),
  },
  rightBottomMain: {
    width: '50%',
    borderTop: `solid 2px ${theme.palette.gold.main}`,
    borderRight: `solid 2px ${theme.palette.gold.main}`,
    paddingLeft: theme.spacing(10),
    paddingTop: theme.spacing(4),
  },
  topInfo: {
    flex: 1,
  },
  bottomInfo: {
    flex: 2,
  },
  twitter: {
    textDecoration: 'none',
    color: theme.palette.gold.main,
    transition: 'color 0.3s ease-in-out',
    '& img': {
      verticalAlign: 'middle',
      marginRight: theme.spacing(1),
      width: 18,
      height: 18,
    },
    '&:hover': {
      color: theme.palette.white.main,
    },
  },
  logo: {
    width: 180,
  },
});

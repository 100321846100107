export default theme => ({
  blockContainer: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  header: {
    position: 'fixed',
    zIndex: 50,
    width: '100%',
    height: '20vh',
    // height: '20%',
  },
  rowTopMargin: {
    height: '3vh',
    background: theme.palette.green.main,
    borderBottom: `solid 2px ${theme.palette.gold.main}`,
  },
  rowTop: {
    background: theme.palette.green.main,
    borderBottom: `solid 2px ${theme.palette.gold.main}`,
    flex: 1,
  },
  headerFiller: {
    height: '20vh',
    // height: '20%',
  },
  rowMain: {
    // marginTop: '20vh',
    position: 'relative',
    flex: 1,
    '& .main': {
      borderRight: `solid 2px ${theme.palette.gold.main}`,
      borderLeft: `solid 2px ${theme.palette.gold.main}`,
    },
  },
  rowBottom: {
    height: 'calc(20% - 3vh)',
    borderTop: `solid 2px ${theme.palette.gold.main}`,
    borderBottom: `solid 2px ${theme.palette.gold.main}`,
  },
  rowBottomMargin: {
    flex: 1,
    background: theme.palette.green.main,
    maxHeight: '10vh',
    borderBottom: `solid 2px ${theme.palette.gold.main}`,
  },
  left: {
    position: 'relative',
    minWidth: 25,
    maxWidth: '3vh',
    flex: 1,
    background: theme.palette.green.main,
    borderRight: `solid 2px ${theme.palette.gold.main}`,
  },
  right: {
    position: 'relative',
    minWidth: 25,
    maxWidth: '3vh',
    flex: 1,
    background: theme.palette.green.main,
    borderLeft: `solid 2px ${theme.palette.gold.main}`,
  },
  insideLeft: {
    position: 'relative',
    minWidth: 25,
    maxWidth: '3vh',
    flex: 1,
    background: theme.palette.green.main,
  },
  insideRight: {
    position: 'relative',
    minWidth: 25,
    maxWidth: '3vh',
    flex: 1,
    background: theme.palette.green.main,
  },
  halfCircle: {
    maxWidth: '50%',
  },
  main: {
    position: 'relative',
    width: 'calc(50vw - 12vh)',
    overflow: 'hidden',
    flex: 1,
    '&.center': {
      borderTop: `solid 2px ${theme.palette.gold.main}`,
      borderBottom: `solid 2px ${theme.palette.gold.main}`,
    },
  },
  title: {
    color: theme.palette.gold.main,
    position: 'absolute',
    zIndex: 1,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    textAlign: 'center',
  },
  line: {
    position: 'absolute',
    zIndex: 1,
    height: '100%',
    width: 2,
    top: 0,
    left: '50%',
    background: theme.palette.gold.main,
  },
  lineMiddle: {
    position: 'absolute',
    zIndex: 1,
    height: 2,
    width: '100%',
    top: '50%',
    left: 0,
    background: theme.palette.gold.main,
  },
  bgsquare: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '50%',
    height: '100%',
    backgroundColor: theme.palette.green.main,
  },
  circle: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '200%',
    width: '100%',
    border: `solid 2px ${theme.palette.gold.main}`,
    borderRadius: '100%',
  },
  circleBottom: {
    position: 'absolute',
    zIndex: 1,
    top: '-100%',
    left: 0,
    height: '200%',
    width: '100%',
  },
  mainCircle: {
    position: 'absolute',
    zIndex: 1,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '100%',
    maxWidth: '100%',
    border: `solid 2px ${theme.palette.gold.main}`,
    borderRadius: '100%',
  },
  circleTopLeft: {
    position: 'absolute',
    bottom: '50%',
    maxHeight: '100%',
    maxWidth: '50%',
  },
  circleBottomLeft: {
    position: 'absolute',
    top: '50%',
    maxHeight: '100%',
    maxWidth: '50%',
  },
  circleTopRight: {
    maxHeight: '50%',
    maxWidth: '100%',
  },
  circleBottomRight: {
    maxHeight: '50%',
    maxWidth: '100%',
  },
  rightBottomCircleBelow: {
    width: '100%',
    left: '50%',
    height: '100%',
    position: 'absolute',
  },
  specialColumn: {
    position: 'relative',
    height: '100%',
    width: '50%',
    left: '50%',
  },
  topRowMain: {
    height: '100%',
  },
  side: {
    flex: 1,
    backgroundColor: theme.palette.green.main,
    minHeigth: 0,
  },
  logo: {
    width: '15vw',
    maxWidth: 60,
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import Stack from '@mui/material/Stack';
import { withStyles } from '@mui/styles';
import { IconButton, Typography } from '@mui/material/';
import Button from '../../components/Button';
import { ReactComponent as Add } from '../../res/add.svg';
import PopupSquared from '../../popups/PopupSquared';
import styles from './styles';
import data from '../../utils/data';

class B10challenges extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b10challenges;
    this.dataPopups = data.popups;
    this.imgBase = `${this.props.baseUrl}/blocks/`;
  }

  renderText(text, key) {
    const { classes } = this.props;
    return (
      <div key={key} className={classes.chapter}>
        {text.title && (
          <Typography variant="subtitle2" className={classes.textTitle}>
            {parse(text.title)}
          </Typography>
        )}
        {text.text && (
          <Typography variant="body2" className={`${classes.text} text`}>
            {parse(text.text)}
          </Typography>
        )}
      </div>
    );
  }

  renderTop() {
    const { classes, onPopup } = this.props;

    return (
      <Stack direction="row" spacing={4} className={classes.flexDirection}>
        <Stack direction="column" className={classes.textContent}>
          <Typography variant="body1" className={classes.subtitle}>
            {parse(this.dataBlock.desc)}
          </Typography>
          <a href={this.dataBlock.url} target="_blank">
            <Button inverse color="gold">
              <Typography variant="caption">{parse(this.dataBlock.btn)}</Typography>
            </Button>
          </a>
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems="center" className={classes.diagram}>
          <div className={classes.square}>
            <div className={classes.circle}></div>
            <div className={`${classes.challenge} ${classes.challenge1}`}>
              <img className={classes.image} src={`${this.imgBase}/${this.dataBlock.challenge1img}`} alt=""></img>
              <IconButton onClick={() => onPopup(this.dataBlock.challenge1popupTrigger, true)} className={classes.btn}>
                <Add />
              </IconButton>
              <Typography variant="subtitle2" className={classes.btnDesc}>
                {parse(this.dataBlock.challenge1desc)}
              </Typography>
            </div>
            <div className={`${classes.challenge} ${classes.challenge2}`}>
              <img className={classes.image} src={`${this.imgBase}/${this.dataBlock.challenge2img}`} alt=""></img>
              <IconButton onClick={() => onPopup(this.dataBlock.challenge2popupTrigger, true)} className={classes.btn}>
                <Add />
              </IconButton>
              <Typography variant="subtitle2" className={classes.btnDesc}>
                {parse(this.dataBlock.challenge2desc)}
              </Typography>
            </div>
            <div className={`${classes.challenge} ${classes.challenge3}`}>
              <img className={classes.image} src={`${this.imgBase}/${this.dataBlock.challenge3img}`} alt=""></img>
              <IconButton onClick={() => onPopup(this.dataBlock.challenge3popupTrigger, true)} className={classes.btn}>
                <Add />
              </IconButton>
              <Typography variant="subtitle2" className={classes.btnDesc}>
                {parse(this.dataBlock.challenge3desc)}
              </Typography>
            </div>
          </div>
        </Stack>
      </Stack>
    );
  }

  renderBottom() {
    const { classes } = this.props;

    return (
      <Stack direction="column" alignItems="center" className={classes.bottom}>
        <Typography variant="h4" sx={{ pt: '32px', pb: '32px' }} className={classes.subtitle}>
          {parse(this.dataBlock.encart.subtitle)}
        </Typography>
        <Stack direction="row" sx={{ p: '32px' }} spacing={8} className={classes.flexDirection}>
          <Stack direction="column" className={classes.column}>
            {this.dataBlock.encart.desc1.map((desc, key) => this.renderText(desc, key))}
          </Stack>
          <Stack direction="column" className={classes.column}>
            {this.dataBlock.encart.desc2.map((desc, key) => this.renderText(desc, key))}
          </Stack>
        </Stack>
        <img className={classes.banner} src={`${this.imgBase}/${this.dataBlock.encart.banner}`} alt=""></img>
      </Stack>
    );
  }

  render() {
    const { classes, popupsState, onPopup } = this.props;

    return (
      <Stack direction="column" spacing={4} className={classes.blockContainer}>
        {this.renderTop()}
        {this.renderBottom()}
        <PopupSquared
          popupData={this.dataPopups.popupChallengeEnvironment}
          open={!!popupsState.popupChallengeEnvironment}
          onClose={() => onPopup('popupChallengeEnvironment', false)}
        ></PopupSquared>
        <PopupSquared
          popupData={this.dataPopups.popupChallengeLeadership}
          open={!!popupsState.popupChallengeLeadership}
          onClose={() => onPopup('popupChallengeLeadership', false)}
        ></PopupSquared>
        <PopupSquared
          popupData={this.dataPopups.popupChallengeSocial}
          open={!!popupsState.popupChallengeSocial}
          onClose={() => onPopup('popupChallengeSocial', false)}
        ></PopupSquared>
      </Stack>
    );
  }
}

B10challenges.propTypes = {
  baseUrl: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onPopup: PropTypes.func,
  popupsState: PropTypes.object,
};

export default withStyles(styles)(B10challenges);

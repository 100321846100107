import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { withStyles } from '@mui/styles';
import { Stack, Typography } from '@mui/material/';

import styles from './styles';
import data from '../../utils/data';

class Footer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dataBlock = data.components.footer;
    this.imgBase = `${this.props.baseUrl}/common/`;
  }

  render() {
    const { classes /* , baseUrl */ } = this.props;
    return (
      <Stack direction="column" className={classes.blockContainer}>
        <Stack direction="column" className={classes.content}>
          <Stack direction="column" className={classes.category}>
            <Typography variant="caption" className={classes.title}>
              {parse(this.dataBlock.phoneLabel)}
            </Typography>
            <Typography variant="caption" className={classes.text}>
              {parse(this.dataBlock.phone)}
            </Typography>
          </Stack>
          <Stack direction="column" className={classes.category}>
            <Typography variant="caption" className={classes.title}>
              {parse(this.dataBlock.addressLabel)}
            </Typography>
            <Typography variant="caption" className={classes.text}>
              {parse(this.dataBlock.addressStreet)}
            </Typography>
          </Stack>
          <Stack direction="column" className={classes.category}>
            <Typography variant="caption" className={classes.text}>
              {parse(this.dataBlock.addressPO)}
            </Typography>
            <Typography variant="caption" className={classes.text}>
              {parse(this.dataBlock.addressCity)}
            </Typography>
          </Stack>
          <Stack direction="column" className={classes.category}>
            <Typography variant="caption" className={classes.title}>
              {parse(this.dataBlock.mailLabel)}
            </Typography>
            <Typography variant="caption" className={classes.text}>
              {parse(this.dataBlock.mail)}
            </Typography>
          </Stack>
          <Stack direction="column" className={classes.category}>
            <Typography variant="caption" className={classes.title}>
              {parse(this.dataBlock.socialLabel)}
            </Typography>
            <a href="https://twitter.com/_erafp_" target="_blank" className={classes.twitter}>
              <Typography variant="caption">
                <img className={classes.image} src={`${this.imgBase}/${this.dataBlock.twitterLogo}`} alt=""></img>
                {parse(this.dataBlock.twitter)}
              </Typography>
            </a>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between" className={classes.logoWrapper}>
          <img className={classes.logo} src={`${this.imgBase}${this.dataBlock.logo}`} alt="" />
          <Stack diretion="column" className={classes.luciole}>
            <Typography variant="caption" className={classes.text}>
              {parse(this.dataBlock.luciole)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
};

export default withStyles(styles)(Footer);

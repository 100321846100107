export default theme => ({
  blockContainer: {
    position: 'relative',
    backgroundColor: theme.palette.beige.main,
  },
  banner: {
    width: '12vw',
    height: '100%',
  },
  articleTitle: {
    paddingLeft: theme.spacing(20),
    textIndent: -160,
    lineHeight: '54px !important',
    color: theme.palette.green.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem !important',
      textIndent: 0,
      textAlign: 'center',
      lineHeight: '40px !important',
      paddingLeft: 0,
      paddingBottom: theme.spacing(4),
    },
  },
  content: {
    padding: `${theme.spacing(9)} calc(8vw) ${theme.spacing(3)} ${theme.spacing(7)}`,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
  },
  subtitle: {
    fontWeight: 'bold !important',
    fontFamily: '"Bodina Moda" !important',
    color: theme.palette.gold.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem !important',
      fontWeight: '600 !important',
    },
  },
});

export default theme => ({
  blockContainer: {
    position: 'relative',
    backgroundColor: theme.palette.white.main,
  },
  articleTitle: {
    paddingTop: theme.spacing(6),
    paddingLeft: theme.spacing(9),
    textIndent: -24,
    lineHeight: '45px !important',
    color: theme.palette.green.main,
    '& .title': {
      display: 'block',
      marginLeft: theme.spacing(18),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.3rem !important',
      textIndent: 0,
      textAlign: 'center',
      lineHeight: '40px !important',
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(4),
    },
  },
  leftContent: {
    position: 'absolute',
    top: 'calc(4% + 8vw)',
    left: 'calc(20% + 29vh)',
    width: 'min-content',
  },
  subtitle: {
    color: theme.palette.gold.main,
    marginBottom: `${theme.spacing(2)} !important`,
  },
  banner: {
    width: '12vw',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      objectFit: 'contain',
    },
  },
  bannerMobile: {
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      height: '80px',
      position: 'absolute',
    },
  },
  events: {
    padding: `${theme.spacing(9)} calc(8vw) ${theme.spacing(3)} ${theme.spacing(7)}`,
    flexWrap: 'wrap',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      marginTop: '0',
    },
  },
  event: {
    width: 'calc(50% - 64px)',
    maxWidth: 500,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  eventTitle: {
    position: 'relative',
    height: 30,
    color: theme.palette.gold.main,
    [theme.breakpoints.down('md')]: {
      height: 10,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      zIndex: 5,
      top: 0,
      left: 0,
      width: '100%',
      height: 1,
      backgroundColor: theme.palette.gold.main,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      zIndex: 5,
      top: '100%',
      left: 0,
      width: '100%',
      height: 1,
      backgroundColor: theme.palette.gold.main,
    },
  },
  illustration: {
    position: 'relative',
    height: '100%',
  },
  date: {
    position: 'relative',
    top: 3,
  },
  desc: {
    textAlign: 'right',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    color: theme.palette.grey.light,
  },
});

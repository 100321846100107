import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

import styles from './styles';

class ButtonWithIcon extends React.PureComponent {
  render() {
    const { children, classes, className, iconPosition, color, icon, ...other } = this.props;
    const iconPositionClass = { above: 'iconAbove', center: 'iconCenter', top: 'iconTop', right: 'iconRight' };
    return (
      <Button
        focusRipple
        className={`${className} ${classes.button} 
          ${iconPositionClass[iconPosition]} 
          ${classes[color] || classes.black} `}
        {...other}
        startIcon={icon}
      >
        <Typography variant="h6" className={`${classes.label}`}>
          {children}
        </Typography>
      </Button>
    );
  }
}

ButtonWithIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.object.isRequired,
  iconPosition: PropTypes.oneOf(['above', 'top', 'center', 'right']),
};

ButtonWithIcon.defaultProps = {
  color: 'black',
  iconPosition: 'center',
};

export default withStyles(styles)(ButtonWithIcon);

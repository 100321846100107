import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { withStyles } from '@mui/styles';
import { IconButton, Stack, Typography } from '@mui/material/';
import { ReactComponent as Add } from '../../res/add.svg';
import PopupRounded from '../../popups/PopupRounded';
import Anchor from '../../components/Anchor';

import styles from './styles';
import data from '../../utils/data';

class B05missions extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b05missions;
    this.dataPopups = data.popups;
    this.imgBase = `${this.props.baseUrl}/blocks/`;
  }

  render() {
    const { classes, onPopup, popupsState } = this.props;
    const title = parse(this.dataBlock.title);

    return (
      <div className={classes.blockContainer}>
        <Anchor id="missions" />
        <Typography variant="h1" className={classes.articleTitle}>
          {title}
        </Typography>
        <div
          className={classes.imgSubtitle}
          style={{
            backgroundImage: `url(${this.imgBase}${this.dataBlock.banner})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <Typography variant="h3" className={classes.subtitle}>
            {parse(this.dataBlock.subtitle)}
          </Typography>
        </div>
        <Stack
          sx={{ flexDirection: { sm: 'row', xs: 'column' } }}
          justifyContent="space-around"
          className={classes.articles}
        >
          {this.dataBlock.buttons.map((item, idx) => (
            <div key={idx} className={classes.article}>
              <IconButton onClick={() => onPopup(item.popupTrigger, true)} className={classes.btn}>
                <Add />
              </IconButton>
              <Typography variant="subtitle2" className={classes.articleSubtitle}>
                {parse(item.text)}
              </Typography>
            </div>
          ))}
        </Stack>
        <PopupRounded
          color="lime"
          colorText="green"
          colorTitle="green"
          popupData={this.dataPopups.popupMissionSupplement}
          open={!!popupsState.popupMissionSupplement}
          onClose={() => onPopup('popupMissionSupplement', false)}
        ></PopupRounded>
        <PopupRounded
          color="lime"
          colorText="green"
          colorTitle="green"
          popupData={this.dataPopups.popupMissionValues}
          open={!!popupsState.popupMissionValues}
          onClose={() => onPopup('popupMissionValues', false)}
        ></PopupRounded>
        <PopupRounded
          color="lime"
          colorText="green"
          colorTitle="green"
          popupData={this.dataPopups.popupMissionCompromise}
          open={!!popupsState.popupMissionCompromise}
          onClose={() => onPopup('popupMissionCompromise', false)}
        ></PopupRounded>
      </div>
    );
  }
}

B05missions.propTypes = {
  baseUrl: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onPopup: PropTypes.func,
  popupsState: PropTypes.object,
};

export default withStyles(styles)(B05missions);

import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import Stack from '@mui/material/Stack';
import { withStyles } from '@mui/styles';
import { Typography } from '@mui/material/';
import Button from '../../components/Button';
import Anchor from '../../components/Anchor';

import styles from './styles';
import data from '../../utils/data';

class B07info extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b07info;
    this.renderText = this.renderText.bind(this);
  }

  renderText(text, key) {
    const { classes } = this.props;
    return (
      <div key={key} className={classes.chapter}>
        {text.title && (
          <Typography variant="subtitle2" className={classes.textTitle}>
            {parse(text.title)}
          </Typography>
        )}
        {text.text && (
          <Typography variant="body2" className={`${classes.text} text`}>
            {parse(text.text)}
          </Typography>
        )}
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Stack direction="column" className={classes.blockContainer}>
        <Anchor id="info" />
        <Stack direction="column" className={classes.content}>
          <Typography variant="h1" className={classes.title}>
            {parse(this.dataBlock.title)}
          </Typography>
          <Stack direction="row" sx={{ pb: '32px' }} spacing={8} className={classes.flexColumn}>
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
              className={`${classes.column} ${classes.left}`}
            >
              <Typography variant="body1" className={classes.subtitle}>
                {parse(this.dataBlock.subtitle)}
              </Typography>
              <a href={this.dataBlock.url} target="_blank">
                <Button inverse color="gold">
                  <Typography variant="caption">{parse(this.dataBlock.btn)}</Typography>
                </Button>
              </a>
            </Stack>
            <Stack direction="column" className={`${classes.column} ${classes.right}`}>
              {this.dataBlock.desc.map((desc, key) => this.renderText(desc, key))}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }
}

B07info.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(B07info);

export default theme => ({
  blockContainer: {
    backgroundColor: theme.palette.beige.main,
    padding: `${theme.spacing(9)} calc(8vw) ${theme.spacing(3)} ${theme.spacing(7)}`,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
  },
  column: {
    [theme.breakpoints.down('md')]: {
      margin: '0 !important',
    },
  },
  flexDirection: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column !important',
    },
  },
  textContent: {
    flex: 2,
    '& a': {
      textDecoration: 'none',
    },
  },
  subtitle: {
    fontWeight: '500 !important',
    color: theme.palette.gold.main,
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      textAlign: 'center',
    },
  },
  diagram: {
    flex: 5,
    [theme.breakpoints.down('md')]: {
      margin: '0 !important',
    },
  },
  square: {
    position: 'relative',
    width: 500,
    height: 500,
    [theme.breakpoints.down('md')]: {
      width: 300,
      height: 300,
      marginTop: '50px',
    },
  },
  circle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    borderRadius: '50%',
    border: `dashed 1px ${theme.palette.green.main}`,
  },
  challenge: {
    position: 'absolute',
    width: 250,
  },
  challenge1: {
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    [theme.breakpoints.down('md')]: {
      top: -20,
    },
  },
  challenge2: {
    top: '50%',
    left: -25,
    [theme.breakpoints.down('md')]: {
      top: '60%',
      left: '-80px',
    },
  },
  challenge3: {
    top: '50%',
    right: -25,
    [theme.breakpoints.down('md')]: {
      top: '60%',
      right: '-80px',
    },
  },
  image: {
    position: 'absolute',
    width: 140,
    height: 140,
    left: '50%',
    transform: 'translateX(-50%)',
    [theme.breakpoints.down('md')]: {
      width: 90,
      height: 90,
    },
  },
  btn: {
    position: 'absolute',
    top: 106,
    left: 150,
    padding: '0 !important',
    '& .icon-stroke': {
      transition: 'stroke 0.3s ease-in-out',
      stroke: theme.palette.green.main,
    },
    '&:hover .icon-stroke': {
      stroke: theme.palette.beige.main,
    },
    '& .icon-needfill': {
      transition: 'fill 0.3s ease-in-out',
      fill: theme.palette.beige.main,
    },
    '&:hover .icon-needfill': {
      fill: theme.palette.green.main,
    },
    [theme.breakpoints.down('md')]: {
      top: 60,
      left: 140,
    },
  },
  btnDesc: {
    position: 'absolute',
    top: 150,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'fit-content',
    margin: 'auto',
    textAlign: 'center',
    backgroundColor: theme.palette.beige.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8rem !important',
      top: 100,
    },
  },
  bottom: {
    backgroundColor: theme.palette.white.main,
  },
  banner: {
    width: '100%',
    objectFit: 'cover',
  },
  textTitle: {
    maxWidth: 350,
    fontWeight: 'bold !important',
    paddingBottom: theme.spacing(2),
    color: theme.palette.green.main,
    '& b': {
      display: 'block',
      fontWeight: 'bold',
      fontSize: '1.375rem',
      lineHeight: 1.44,
      paddingBottom: theme.spacing(2),
    },
  },
  text: {
    fontWeight: '300 !important',
    color: theme.palette.green.main,
    paddingBottom: theme.spacing(3),
    '& .paragraph': {
      paddingBottom: theme.spacing(1),
      '&:after': {
        content: "''",
        display: 'block',
        height: 0,
        width: '100%',
      },
    },
  },
});

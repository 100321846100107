import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { withStyles } from '@mui/styles';
import { Hidden, Stack, Typography } from '@mui/material/';
import ButtonWithIcon from '../ButtonWithIcon';
import { ReactComponent as Download } from '../../res/download.svg';
import styles from './styles';
import data from '../../utils/data';
import { ReactComponent as Close } from '../../res/closeMenu.svg';
import { ReactComponent as Add } from '../../res/add.svg';

class Menu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dataBlock = data.components.menu;
  }

  render() {
    const { classes, toggleMenu, openMenu } = this.props;
    return (
      <Stack direction="row" spacing={0}>
        <div className={`${classes.overlay} ${openMenu ? classes.showOverlay : ''}`}></div>
        <Stack
          direction="column"
          justifyContent={{ sm: 'space-between', md: 'space-around' }}
          className={`${classes.blockContainer} ${openMenu ? classes.open : ''}`}
        >
          {this.dataBlock.chapters.map((item, idx) => (
            <a key={idx} href={`#${item.anchor}`} onClick={() => toggleMenu(false)} className={classes.link}>
              <Typography className={classes.linkText} variant="h5">
                {parse(item.title)}
              </Typography>
            </a>
          ))}
          <a href={this.dataBlock.btnUrlPdf} target="_blank">
            <ButtonWithIcon
              icon={<Download />}
              color="gold"
              iconPosition="right"
              className={`${classes.btn} ${classes.btnRight}`}
            >
              {parse(this.dataBlock.btnDownload)}
            </ButtonWithIcon>
          </a>
          <Hidden mdDown>
            <Close className={classes.close} onClick={() => toggleMenu(false)} />
          </Hidden>
          <Hidden lgUp>
            <a href={this.dataBlock.btnUrlLink} target="_blank">
              <ButtonWithIcon
                icon={<Add />}
                color="gold"
                iconPosition="right"
                className={`${classes.btn} ${classes.btnLeft}`}
              >
                {parse(this.dataBlock.btnLink)}
              </ButtonWithIcon>
            </a>
          </Hidden>
        </Stack>
      </Stack>
    );
  }
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleMenu: PropTypes.func,
  openMenu: PropTypes.bool,
};

export default withStyles(styles)(Menu);

export default theme => ({
  blockContainer: {
    backgroundColor: theme.palette.green.main,
    marginBottom: theme.spacing(41.25),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(15),
    },
  },
  mainTitle: {
    color: theme.palette.gold.main,
    padding: theme.spacing(6),
    paddingBottom: 0,
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9rem !important',
      textAlign: 'center',
    },
  },
  descContainer: {
    width: '100%',
    padding: theme.spacing(6),
    color: theme.palette.white.main,
    gap: '75px',
  },
  leftBlock: {
    borderRight: `solid 2px ${theme.palette.gold.main}`,
    borderBottom: `solid 2px ${theme.palette.gold.main}`,
    width: '50vw',
    [theme.breakpoints.down('md')]: {
      border: 'none',
      width: '100vw',
    },
  },
  desc: {
    columns: 2,
    [theme.breakpoints.down('md')]: {
      columns: 'initial',
    },
  },
  desc1: {
    flex: 1,
  },
  desc2: {
    flex: 1,
  },
});

export default theme => ({
  blockContainer: {
    backgroundColor: theme.palette.green.main,
    padding: `${theme.spacing(9)} calc(8vw) ${theme.spacing(9)} ${theme.spacing(7)}`,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
  },
  title: {
    lineHeight: '54px !important',
    color: theme.palette.white.main,
    textAlign: 'center',
    maxWidth: 700,
    paddingBottom: theme.spacing(4),
    '& .gold': {
      color: theme.palette.gold.main,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem !important',
      textIndent: 0,
      textAlign: 'center',
      lineHeight: '40px !important',
      paddingLeft: 0,
      paddingBottom: theme.spacing(4),
    },
  },
  value: {
    position: 'relative',
    flex: 1,
    minWidth: 180,
    maxWidth: 300,
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(4),
    },
  },
  imgWrapper: {
    position: 'relative',
  },
  image: {
    width: '90%',
    display: 'block',
    margin: 'auto',
    borderRadius: 40,
    border: `solid 1px ${theme.palette.gold.main}`,
    [theme.breakpoints.down('md')]: {
      width: '60%',
    },
  },
  btn: {
    position: 'absolute !important',
    bottom: 0,
    right: 0,
    '&:hover .icon-stroke': {
      stroke: theme.palette.green.main,
    },
    '& .icon-needfill': {
      transition: 'fill 0.3s ease-in-out',
      fill: theme.palette.green.main,
    },
    '&:hover .icon-needfill': {
      fill: theme.palette.gold.main,
    },
    [theme.breakpoints.down('md')]: {
      right: '17%',
    },
  },
  desc: {
    color: theme.palette.gold.main,
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      width: '75%',
      paddingLeft: '70px',
    },
  },
  content: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column !important',
    },
  },
});

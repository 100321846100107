export default theme => ({
  blockContainer: {
    position: 'relative',
    backgroundColor: theme.palette.white.main,
    padding: `${theme.spacing(9)} calc(8vw) ${theme.spacing(3)} ${theme.spacing(7)}`,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
  },
  title: {
    margin: 'auto',
    textIndent: -80,
    lineHeight: '45px !important',
    color: theme.palette.gold.main,
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      textIndent: 'initial',
      paddingLeft: 0,
      fontSize: '2rem !important',
      textAlign: 'center',
      lineHeight: '40px !important',
    },
  },
  subtitle: {
    color: theme.palette.beige.main,
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      padding: theme.spacing(4),
    },
  },
  top: {
    backgroundColor: theme.palette.gold.main,
    color: theme.palette.white.main,
  },
  banner: {
    width: '100%',
    objectFit: 'cover',
  },
  textTitle: {
    maxWidth: 350,
    fontWeight: 'bold !important',
    paddingBottom: theme.spacing(2),
    color: theme.palette.green.main,
    '& b': {
      display: 'block',
      fontWeight: 'bold',
      fontSize: '1.375rem',
      lineHeight: 1.44,
      paddingBottom: theme.spacing(2),
    },
  },
  text: {
    fontWeight: '100 !important',
    paddingBottom: theme.spacing(3),
    '& .paragraph': {
      paddingBottom: theme.spacing(1),
      '&:after': {
        content: "''",
        display: 'block',
        height: 0,
        width: '100%',
      },
    },
    '& a': {
      color: theme.palette.beige.main,
      transition: 'color 0.3s ease-in-out',
    },
    '& a:hover': {
      color: theme.palette.white.main,
    },
  },
  aside: {
    '& .text': {
      columns: 2,
      [theme.breakpoints.down('md')]: {
        columns: 'initial',
      },
    },
  },
  desc: {
    color: theme.palette.green.main,
  },
});

export default theme => ({
  blockContainer: {
    backgroundColor: theme.palette.white.main,
    padding: `${theme.spacing(2)} calc(8vw) ${theme.spacing(3)} ${theme.spacing(7)}`,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
  },
  title: {
    fontWeight: 'bold !important',
    fontFamily: '"Bodina Moda" !important',
    color: theme.palette.green.main,
    paddingBottom: theme.spacing(4),
  },
  subtitle: {
    fontWeight: 'bold !important',
    color: theme.palette.green.main,
    '& .goals': {
      textAlign: 'center',
      display: 'block',
    },
  },
  square: {
    position: 'relative',
    width: 500,
    height: 500,
    [theme.breakpoints.down('md')]: {
      width: 350,
      height: 350,
    },
  },
  circle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    borderRadius: '50%',
    border: `dashed 1px ${theme.palette.green.main}`,
  },
  btn: {
    position: 'absolute',
    top: '60%',
    padding: '0 !important',
    '& .icon-needfill': {
      fill: 'transparent',
      transition: 'fill 0.3s ease-in-out',
    },
    '&:hover .icon-needfill': {
      fill: theme.palette.white.main,
    },
  },
  goal: {
    position: 'absolute',
    width: 200,
    height: 200,
    borderRadius: '50%',
    backgroundColor: 'red',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: 130,
      height: 130,
    },
  },
  goal1: {
    backgroundColor: theme.palette.green.main,
    color: theme.palette.gold.main,
    top: 0,
    left: 0,
    '& .icon-stroke': {
      stroke: theme.palette.gold.main,
    },
  },
  goal2: {
    backgroundColor: theme.palette.beige.main,
    color: theme.palette.lime.dark,
    top: 0,
    right: 0,
    '& .icon-stroke': {
      stroke: theme.palette.lime.main,
    },
  },
  goal3: {
    backgroundColor: theme.palette.gold.main,
    color: theme.palette.white.main,
    bottom: 0,
    left: 0,
    '& .icon-stroke': {
      transition: 'all 0.3s ease-in-out',
      stroke: theme.palette.white.main,
    },
    '& .btn:hover .icon-needfill': {
      fill: `${theme.palette.green.main} !important`,
    },
  },
  goal4: {
    backgroundColor: theme.palette.lime.dark,
    color: theme.palette.green.main,
    bottom: 0,
    right: 0,
    '& .icon-stroke': {
      stroke: theme.palette.green.main,
    },
  },
  goal5: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.green.main,
    border: `solid 1px ${theme.palette.green.main}`,
    width: 160,
    height: 160,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '& .number': {
      display: 'block',
      fontSize: '1.42rem',
      lineHeight: '1.1',
    },
  },
  desc: {
    position: 'absolute',
    bottom: '50%',
    left: '50%',
    transform: 'translateX(-50%)',
    maxWidth: 150,
    fontWeight: 'bold !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem !important',
    },
  },
  centerDesc: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    right: '10%',
    maxWidth: 160,
    bottom: 'initial',
    transform: 'initial',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.85rem !important',
    },
  },
});

export default theme => ({
  container: {
    position: 'absolute',
    width: 0,
    pointerEvents: 'none',
    height: 0,
    left: 0,
    top: 0,
    [theme.breakpoints.down('md')]: {
      top: '-20vh',
    },
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { withStyles } from '@mui/styles';
import { Stack, Typography } from '@mui/material';
import { ReactComponent as Download } from '../../res/download.svg';
import { ReactComponent as Add } from '../../res/add.svg';
import ButtonWithIcon from '../ButtonWithIcon';
import styles from './styles';
import data from '../../utils/data';

class FixedColumn extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.components.fixedColumn;
    this.imgBase = `${this.props.baseUrl}/common/`;
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.column}>
        <div className={classes.left}>
          <div className={classes.top}></div>
          <div className={classes.mainMargin}></div>
          <div className={`${classes.main} main`}></div>
          <div className={`${classes.medium} medium`}></div>
          <div className={classes.footer}></div>
        </div>
        <div className={classes.right}>
          <div className={classes.top}></div>
          <div className={classes.mainMargin}>
            <img className={classes.logo} src={`${this.imgBase}${this.dataBlock.logo}`} alt="" />
          </div>
          <div className={`${classes.main} main`}>
            <div className={classes.circle}>
              <Stack direction="column" className={classes.textContent}>
                <Typography variant="overline" sx={{ pb: '16px' }}>
                  {parse(this.dataBlock.title)}
                </Typography>
                <a href={this.dataBlock.btnUrlPdf} target="_blank">
                  <ButtonWithIcon icon={<Download />} color="gold" iconPosition="right" className={classes.button}>
                    {parse(this.dataBlock.btnDownload)}
                  </ButtonWithIcon>
                </a>
                <a href={this.dataBlock.btnUrlLink} target="_blank">
                  <ButtonWithIcon icon={<Add />} color="gold" iconPosition="right" className={classes.button}>
                    {parse(this.dataBlock.btnLink)}
                  </ButtonWithIcon>
                </a>
              </Stack>
            </div>
          </div>
          <div className={`${classes.medium} medium`}>
            <Typography variant="overline"></Typography>
          </div>
          <div className={`${classes.footer}`}></div>
        </div>
      </div>
    );
  }
}

FixedColumn.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
};

export default withStyles(styles)(FixedColumn);

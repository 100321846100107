import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import Stack from '@mui/material/Stack';
import { withStyles } from '@mui/styles';
import { Typography } from '@mui/material/';
import Anchor from '../../components/Anchor';

import styles from './styles';
import data from '../../utils/data';

class B11investor extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b11investor;
    this.imgBase = `${this.props.baseUrl}/blocks/`;
  }

  renderText(text, key) {
    const { classes } = this.props;
    return (
      <div key={key} className={classes.chapter}>
        {text.title && (
          <Typography variant="subtitle2" className={classes.textTitle}>
            {parse(text.title)}
          </Typography>
        )}
        {text.text && (
          <Typography variant="body2" className={`${classes.text} text`}>
            {parse(text.text)}
          </Typography>
        )}
      </div>
    );
  }

  renderTop() {
    const { classes } = this.props;

    return (
      <Stack direction="column" alignItems="center" className={classes.top}>
        <Typography variant="h4" sx={{ pt: '32px', pb: '32px' }} className={classes.subtitle}>
          {parse(this.dataBlock.encart.subtitle)}
        </Typography>
        <Stack sx={{ p: '32px' }} className={classes.aside}>
          {this.dataBlock.encart.desc.map((desc, key) => this.renderText(desc, key))}
        </Stack>
        <img className={classes.banner} src={`${this.imgBase}/${this.dataBlock.encart.banner}`} alt=""></img>
      </Stack>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Stack direction="column" spacing={4} className={classes.blockContainer}>
        <Anchor id="investor" />
        <Typography variant="h1" className={classes.title}>
          {parse(this.dataBlock.title)}
        </Typography>
        {this.renderTop()}
        <Typography variant="body2" className={classes.desc}>
          {parse(this.dataBlock.subtitle)}
        </Typography>
      </Stack>
    );
  }
}

B11investor.propTypes = {
  baseUrl: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(B11investor);

function loop(theme, res, list, type) {
  const suffixes = { main: '', light: 'light', dark: 'dark' };
  const suffixe = suffixes[type];
  list.forEach(key => {
    res[`${key}${suffixe}`] = {
      backgroundColor: `${theme.palette[key].main} !important`,
      color: `${theme.palette[key].contrastText} !important`,
      stroke: `${theme.palette[key].contrastText} !important`,
      fill: `${theme.palette[key].contrastText} !important`,
      borderColor: `${theme.palette[key].main} !important`,
      '&:hover': {
        backgroundColor: 'transparent !important',
        borderColor: `${theme.palette[key].main} !important`,
        color: `${theme.palette[key].main} !important`,
        stroke: `${theme.palette[key].main} !important`,
        fill: `${theme.palette[key].main} !important`,
      },
    };

    res[`${key}${suffixe}-inverse`] = {
      backgroundColor: theme.palette.white.main,
      borderColor: `${theme.palette[key].main} !important`,
      color: `${theme.palette[key].main} !important`,
      stroke: `${theme.palette[key].main} !important`,
      fill: `${theme.palette[key].main} !important`,
      '&:hover': {
        backgroundColor: `${theme.palette[key].main} !important`,
        borderColor: `${theme.palette[key].contrastText} !important`,
        color: `${theme.palette[key].contrastText} !important`,
        stroke: `${theme.palette[key].contrastText} !important`,
        fill: `${theme.palette[key].contrastText} !important`,
        '& line': {
          stroke: `${theme.palette[key].contrastText} !important`,
        },
      },
    };

    res[`${key}${suffixe}-underline`] = {
      backgroundColor: 'transparent',
      border: 'transparent',
      color: theme.palette.black.main,
      borderRadius: 0,
      '&:hover': {
        color: theme.palette[key].main,
        '& svg': {
          transform: 'rotate(45deg)',
        },
      },
    };

    res[`${key}${suffixe}-labelUnderline`] = {
      borderBottom: `8px solid ${theme.palette[key].main}`,
      paddingLeft: 0,
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        borderBottom: `4px solid ${theme.palette[key].main}`,
      },
    };

    res[`${key}${suffixe}-iconUnderline`] = {
      flexShrink: 0,
      backgroundColor: theme.palette[key].main,
      borderColor: 'transparent',
      borderRadius: '50%',
      color: theme.palette[key].contrastText,
      stroke: theme.palette[key].contrastText,
      fill: theme.palette[key].contrastText,
      padding: theme.spacing(1),
      boxSizing: 'content-box',
    };
  });
}

export default theme => {
  const main = ['white', 'black', 'grey', 'beige', 'gold', 'green', 'lime'];
  const light = ['grey', 'gold', 'green', 'lime'];
  const dark = ['green', 'lime'];

  const res = {
    button: {
      borderRadius: '50px !important',
      borderStyle: 'solid !important',
      borderWidth: '1px !important',
      fontWeight: 500,
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      transition: 'all 0.3s',
      '& svg': {
        transition: 'transform 0.3s',
      },
    },
    label: {
      textAlign: 'center',
      paddingLeft: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        textAlign: 'left',
      },
    },
    medium: {
      fontSize: '0.875rem',
      // padding: '2px 15px',
      [theme.breakpoints.down('sm')]: {
        padding: '5px 20px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '5px',
      },
    },
    small: {
      // padding: '3px 14px 3px 10px',
      fontSize: '0.70rem',
    },
    'icon-medium': {
      width: 30,
      height: 30,
      [theme.breakpoints.down('sm')]: {
        width: 25,
        height: 25,
      },
      [theme.breakpoints.down('xs')]: {
        width: 25,
        height: 25,
        margin: '5px',
      },
    },
    'icon-small': {
      width: 15,
      height: 15,
    },
    noBorder: {
      borderColor: 'transparent !important',
    },
  };

  loop(theme, res, main, 'main');
  loop(theme, res, light, 'light');
  loop(theme, res, dark, 'dark');

  return res;
};

export default theme => ({
  blockContainer: {
    position: 'relative',
    minHeight: '80%',
    width: '100%',
  },
  content: {
    flex: 1,
    textAlign: 'center',
    backgroundColor: theme.palette.green.main,
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  category: {
    paddingBottom: theme.spacing(1),
  },
  title: {
    fontSize: '0.8rem !important',
    fontWeight: '500 !important',
    color: theme.palette.gold.main,
  },
  text: {
    fontWeight: '300 !important',
    color: theme.palette.gold.main,
  },
  lineMiddle: {
    position: 'absolute',
    top: '50%',
    left: 0,
    zIndex: 1,
    height: 2,
    width: '100%',
    background: theme.palette.gold.main,
  },
  topMain: {
    height: '50%',
  },
  luciole: {
    textAlign: 'center',
    padding: theme.spacing(2, 4),
    backgroundColor: theme.palette.green.main,
  },
  twitter: {
    textDecoration: 'none',
    color: theme.palette.gold.main,
    transition: 'color 0.3s ease-in-out',
    '& img': {
      verticalAlign: 'middle',
      marginRight: theme.spacing(1),
      width: 18,
      height: 18,
    },
    '&:hover': {
      color: theme.palette.white.main,
    },
  },
  logoWrapper: {
    height: '50%',
    backgroundColor: theme.palette.green.main,
    paddingBottom: theme.spacing(4),
  },
  logo: {
    width: 180,
  },
});

export default {
  blocks: {
    b00intro: {
      bg: 'b00-bg.jpg',
      title: 'L’essentiel 2021',
      topRightExtensible: 'b00-circleWTR.png',
      topRight: 'b00-circleWTR.svg',
      topLeft: 'b00-circleWTL.svg',
      bottomLeft: 'b00-circleGBL.svg',
      bottomRight: 'b00-circleGBR.svg',
      mainCircle: 'b00-circle.svg',
    },
    b00introMobile: {
      bg: 'b00Mobile-bg.jpg',
      logo: 'logo-rafp.svg',
      title: 'RAPPORT ANNUEL<br/>L’essentiel 2021',
      bottomRightExtensible: 'b00-circleWBR.png',
      topRight: 'b00-circleWTR.svg',
      topLeft: 'b00-circleGTL.svg',
      bottomLeft: 'b00-circleGBL.svg',
      bottomRight: 'b00-circleWBR.svg',
      mainCircle: 'b00-circle.svg',
    },
    b01retirement: {
      title:
        'LE RÉGIME DE RETRAITE ADDITIONNELLE DE LA FONCTION PUBLIQUE, FONDS DE PENSION DURABLE À LA GESTION RESPONSABLE',
      desc: 'Chaque année, l’ERAFP propose dans son rapport annuel de revenir sur le pilotage du Régime, d’expliciter la stratégie et la performance de l’Établissement ainsi que de fournir une analyse financière et extra financière de ses portefeuilles. Cette année, pour répondre aux nouvelles exigences règlementaires en matière de transparence et de reporting, le rapport annuel 2021 de l’ERAFP est enrichi d’un rapport dédié à sa stratégie ESG. Pensées en complémentarité, ces deux publications offrent ainsi une vision complète des actions de l’Établissement.',
      desc1:
        'Chaque année, l’ERAFP propose dans son rapport annuel de revenir sur le pilotage du Régime, d’expliciter la stratégie et la performance de l’Établissement ainsi que de fournir une analyse financière et extra financière de ses portefeuilles. Cette année, pour répondre aux nouvelles exigences',
      desc2:
        'règlementaires en matière de transparence et de reporting, le rapport annuel 2021 de l’ERAFP est enrichi d’un rapport dédié à sa stratégie ESG. Pensées en complémentarité, ces deux publications offrent ainsi une vision complète des actions de l’Établissement.',
    },
    b02crossedlooks: {
      title: 'REGARDS <span class="title">CROISÉS</span>',
      banner: 'b02-banner.jpg',
      photo: 'b02-directors.png',
      photoTop: 'b02-dominiquelamiot.jpg',
      dominiqueLamiotPopupTrigger: 'popupDominiqueLamiot',
      desc1: '«&nbspEn 2021, le RAFP a continué à affermir sa solidité financière de long terme&nbsp»',
      name1: '<b>DOMINIQUE LAMIOT</b>,<br/> PRÉSIDENT DE L’ERAFP',
      photoBottom: 'b02-laurentgalzy.jpg',
      laurentGalzyPopupTrigger: 'popupLaurentGalzy',
      desc2:
        '«&nbsp2021 a été une année d’approfondissement de la politique de l’ERAFP en matière de transition énergétique&nbsp»',
      name2: '<b>LAURENT GALZY</b>,<br/> DIRECTEUR DE L’ERAFP',
    },
    b03numbers: {
      title: 'CHIFFRES<br/>CLÉS 2021',
      number1: '41,9&nbsp;Md€',
      desc1: 'd’actif net en valeur économique au 31 décembre 2021',
      number2: '5,4&nbsp;%',
      desc2: 'de taux de rendement interne des placements annualisé depuis la création du RAFP (7,9&nbsp;% en 2021)',
      number3: '135,3&nbsp;%',
      desc3: 'de taux de couverture économique après revalorisation',
      number4: '389&nbsp;M€',
      desc4: 'de prestations ordonnancées',
      number5: '4,5&nbsp;M',
      desc5: 'de bénéficiaires<br/>cotisants',
      prefix6: 'près de',
      number6: '43&nbsp;000',
      desc6: 'employeurs<br/>cotisants',
      number7: '1,96&nbsp;Md€',
      desc7: 'des cotisations<br/>collectées',
    },
    b04facts: {
      title: 'FAITS<br/>MARQUANTS',
      banner: 'b04-banner.png',
      bannerMobile: 'b04-bannerMobile.png',
      dates: [
        {
          date: '7 octobre',
          image: 'b04-date1.jpg',
          desc: 'ERAFP renforce ses engagements dans la lutte contre le changement climatique en adoptant des objectifs à horizon 2025',
        },
        {
          date: '18 octobre',
          image: 'b04-date2.jpg',
          desc: 'Mise en ligne de la nouvelle version du site internet de l’ERAFP',
        },
        {
          date: '29 octobre',
          image: 'b04-date3.jpg',
          desc: 'L’ERAFP obtient un prix pour la qualité de son rapport climat',
        },
        {
          date: '3 novembre',
          image: 'b04-date4.jpg',
          desc: 'L’ERAFP signe la déclaration de soutien au développement de la finance à impact de Finance for Tomorrow',
        },
        {
          date: '1er décembre',
          image: 'b04-date5.jpg',
          desc: 'L’ERAFP signe le Finance for Biodiversity Pledge',
        },
        {
          date: '16 décembre',
          image: 'b04-date6.jpg',
          desc: 'Le conseil d’administration de l’ERAFP adopte de nouveaux barèmes de conversion en capital',
        },
      ],
    },
    b05missions: {
      title: 'LES MISSIONS DU RAFP',
      banner: 'b05-banner.jpg',
      subtitle:
        'Opérationnelle depuis 2005, la retraite additionnelle de la fonction publique constitue un régime original',
      buttons: [
        {
          text: 'Apporter un supplément de retraite aux fonctionnaires',
          popupTrigger: 'popupMissionSupplement',
        },
        {
          text: 'Promouvoir les valeurs du service public via l’investissement socialement responsable',
          popupTrigger: 'popupMissionValues',
        },
        {
          text: 'Concilier rendement financier et recherche d’impact',
          popupTrigger: 'popupMissionCompromise',
        },
      ],
    },
    b06subscriptions: {
      title: 'COTISATIONS<br/>& PRESTATIONS',
      desc1: [
        {
          title: 'Un nombre d’employeurs et d’agents cotisants stable',
          text: 'Environ 43&nbsp;000 employeurs et 4,5&nbsp;millions d’agents ont cotisé en&nbsp;2021 au titre du RAFP. Le Régime a ainsi encaissé plus de 1,96&nbsp;Md€ au titre des cotisations de l’exercice&nbsp;2021.',
        },
        {
          title: 'Près de 389&nbsp;M€ versés au titre des prestations',
          text: 'Au total, ce sont 389&nbsp;M€ de prestations qui ont été versées aux bénéficiaires cette année. Ce montant est en hausse par rapport à 2020 (+1,1&nbsp;%).',
        },
      ],
      desc2: [
        {
          title: 'Une montée en charge des prestations en rente',
          text: 'En&nbsp;2021, la montée en charge des prestations en rente s’est poursuivie. Près de 346&nbsp;000 bénéficiaires ont ainsi perçu une rente (+&nbsp;30&nbsp;%). En miroir, le nombre de versements de la prestation RAFP en capital a baissé&nbsp;: environ 118&nbsp;000 bénéficiaires ont perçu un capital en 2021 (-15&nbsp;%).',
        },
        {
          text: 'En&nbsp;2021, la rente moyenne perçue était de 376&nbsp;€, en hausse de 3&nbsp;% par rapport à&nbsp;2020 (365&nbsp;€) et le capital moyen versé, de 3&nbsp;318&nbsp;€.',
        },
      ],
      encart: {
        subtitle: 'LA DÉCLARATION SOCIALE NOMINATIVE',
        banner: 'b06-evolutions.png',
        desc: [
          {
            text: '<span class="paragraph">L’article 43 de la loi n° 2018-727 du 10&nbsp;août&nbsp;2018, pour un État au service d’une société de confiance, prévoit que la déclaration sociale nominative (DSN) , en vigueur dans le secteur privé depuis 2017, se substitue aux modes déclaratifs existants.</span><br/><span class="paragraph">Le décret n° 2018-1048 du 28 novembre 2018 a fixé les dates de passage à la DSN pour chaque catégorie d’employeurs publics au 1er janvier 2020, 1er janvier 2021 et 1er janvier 2022.</span><br/><span class="paragraph"><b>En 2021, 23&nbsp;% des employeurs publics cotisant au RAFP ont basculé en DSN. Le montant total collecté via cet outil l’an passé est de 714&nbsp;M€ (sur 1,96 Md€).</b></span>',
          },
        ],
      },
    },
    b07info: {
      title: 'UNE INFORMATION PLUS FINE DES PARTIES PRENANTES',
      subtitle:
        'La communication du Régime vise à rendre l’action de l’ERAFP plus efficace en offrant à toutes les parties prenantes (bénéficiaires, employeurs, acteurs institutionnels) un niveau d’information leur permettant de participer pleinement à la vie du RAFP.',
      btn: 'DÉCOUVRIR LE SITE',
      url: 'https://www.rafp.fr/',
      desc: [
        {
          title: 'Les employeurs publics&nbsp;: un relais clé',
          text: 'L’ERAFP organise depuis&nbsp;2011 à une fréquence trimestrielle des «&nbsp;Rencontres employeurs sur la retraite additionnelle&nbsp;» en région. Véritables moments d’échange et de partage d’expérience, ces «&nbsp;Rencontres des employeurs publics&nbsp;» sont l’occasion d’un contact direct avec les principaux relais du Régime et permettent ainsi de mieux saisir leurs besoins en termes d’information et d’outils. Après avoir connu une phase d’interruption liée au contexte sanitaire, l’Établissement a repris l’organisation de ces rendez-vous au second semestre&nbsp;2021.',
        },
        {
          title: 'Répondre au mieux aux besoins des bénéficiaires cotisants, priorité du régime',
          text: 'Après cinq ans d’existence, le site internet du RAFP a été entièrement repensé et sa nouvelle version, intégrant de nouvelles rubriques telle que la création d’un espace «&nbsp;retraité&nbsp;», auparavant intégré à la rubrique «&nbsp;bénéficiaires&nbsp;», a été mise en ligne en octobre 2021. Cette nouvelle interface permet désormais aux bénéficiaires d’obtenir une information plus claire et plus précise sur leurs droits, sur leurs cotisation et sur la politique ISR du RAFP.',
        },
      ],
    },
    b08isr: {
      title: 'UNE POLITIQUE ISR<br/>RENFORCÉE',
      banner: 'b08-banner.png',
      desc: 'Depuis 2006, l’ERAFP met en œuvre une politique d’investissement socialement responsable (ISR) qui est au cœur de la stratégie du Régime, ce qui en fait un précurseur dans le domaine. La prise en compte du développement durable dans sa gestion financière a ainsi conduit l’ERAFP à adopter, dès sa création, une démarche d’investissement socialement responsable formalisée par une Charte qui lui est propre.',
    },
    b09values: {
      title:
        'Les <span class="gold">5 valeurs</span> de la Charte ISR de l’ERAFP et ses <span class="gold">18 critères</span>',
      values: [
        {
          image: 'b09-value1.jpg',
          desc: 'État de droit et droits de l’homme',
          popupTrigger: 'popupValueHR',
        },
        {
          image: 'b09-value2.jpg',
          desc: 'Progrès social',
          popupTrigger: 'popupValueProgress',
        },
        {
          image: 'b09-value3.jpg',
          desc: 'Démocratie sociale',
          popupTrigger: 'popupValueDemocracy',
        },
        {
          image: 'b09-value4.jpg',
          desc: 'Environnement',
          popupTrigger: 'popupValueEnvironment',
        },
        {
          image: 'b09-value5.jpg',
          desc: 'Bonne gouvernance et transparence',
          popupTrigger: 'popupValueLeadership',
        },
      ],
    },
    b10challenges: {
      desc: 'Les valeurs ISR portées par la Charte de l’ERAFP constituent <b>autant de réponses aux défis auxquels nous devons collectivement faire face&nbsp;:</b>',
      btn: 'DÉCOUVRIR LA CHARTE ISR',
      url: 'https://rafp.fr/publications/charte-isr-lerafp',
      challenge1img: 'b10-challengeEnvironment.png',
      challenge1desc: 'Défis en termes<br/>d’environnement et de<br/>changement climatique',
      challenge1popupTrigger: 'popupChallengeEnvironment',
      challenge2img: 'b10-challengeLeadership.png',
      challenge2desc: 'Défis en termes<br/>de gouvernance',
      challenge2popupTrigger: 'popupChallengeLeadership',
      challenge3img: 'b10-challengeSocial.png',
      challenge3desc: 'Défis en termes<br/>sociaux',
      challenge3popupTrigger: 'popupChallengeSocial',
      encart: {
        subtitle: 'LES PAS EN AVANT RÉALISÉS EN 2021',
        banner: 'b10-challenges.png',
        desc1: [
          {
            title: 'La prise en compte des enjeux climatiques',
            text: 'En 2021, la stratégie de l’établissement relative à la prise en compte des enjeux liés au climat a connu une nouvelle étape par la formalisation d’une feuille de route à horizon&nbsp;2025 pour atteindre la neutralité carbone. L’ERAFP a ainsi décidé d’activer trois principaux leviers sur lesquels portera son action ses prochaines années&nbsp;: la réduction des émissions de gaz à effet de serre, l’engagement auprès d’entreprises et le financement de la transition énergétique.',
          },
          {
            title: 'La protection et la restauration de la biodiversité',
            text: 'Autre sujet émergent sur lequel l’Établissement s’est positionné&nbsp;: la protection et la restauration de la biodiversité. En signant la déclaration des investisseurs en faveur de la biodiversité, l’ERAFP a reconnu, aux côtés de ses pairs, que la biosphère, qui constitue le fondement de la résilience et du progrès humain, est soumise à une pression croissante. En s’associant à cette déclaration, l’ERAFP identifie pour la première fois cet enjeu comme étant l’un des principaux défis qui se posent dans les années à venir.',
          },
        ],
        desc2: [
          {
            title:
              '<b>«&nbsp;Après s’être engagé, en&nbsp;2020, à atteindre l’objectif de zéro émission nette de gaz à effet de serre pour son portefeuille d’investissement à horizon&nbsp;2050, l’ERAFP s’est fixé des objectifs intermédiaires pour la période 2021-2025&nbsp;»</b>',
          },
          {
            title: 'L’investissement à impact',
            text: 'Enfin, l’ERAFP a également signé en 2021, la déclaration en faveur de la finance à impact de Finance for Tomorrow, qui vise notamment à proposer une meilleure définition de l’investissement à impact. En signant cette déclaration, l’Etablissement appuie la mise en œuvre d’une définition structurée et exigeante de la finance à impact, la promotion d’une démarche d’impact intègre, d’une communication claire et transparente ainsi que des outils appropriés de mesure et de reporting&nbsp; et l’intégration de la finance à impact dans les cadres réglementaires et de marché.&nbsp',
          },
        ],
      },
    },
    b11investor: {
      title: 'UN INVESTISSEUR ACTIF FACE<br/>AU CHANGEMENT CLIMATIQUE',
      encart: {
        subtitle: 'LA GOUVERNANCE DES ENJEUX CLIMATIQUES',
        banner: 'b11-investor.png',
        desc: [
          {
            text: 'L’ERAFP a mis en oeuvre une gouvernance spécifique pour les risques et opportunités liés au climat. Sans être fondamentalement différente de la gouvernance de l’ISR, elle se distingue de celle-ci notamment sur les points suivants&nbsp;:<br/> •&nbsp;au niveau de la gouvernance, des orientations climat sont définies et un suivi dédié de leur mise en œuvre est effectué par le CSPP et le Conseil&nbsp;;<br/> •&nbsp;au niveau de la Direction, en plus de la réalisation d’un contrôle ciblé de la correcte application par les gérants de la démarche climat, un comité de pilotage composé et du directeur, du directeur adjoint en charge de la Gestion technique et financière, de responsables de différentes classes d’actifs et de la responsable ISR, assure assure le suivi de la mise en oeuvre de la feuille de route «&nbsp;climat&nbsp;»&nbsp;;<br/> •&nbsp;au niveau des prestataires, une évaluation spécifique de l’exposition du portefeuille aux enjeux liés au climat est menée (par S&P Global Trucost et Carbone&nbsp;4).<br/>Pour une présentation exhaustive de la gouvernance des enjeux liés au climat, <a href="https://rafp.fr/sites/default/files/2022-06/RAFP-Rapport-durabilite-2021.pdf" target="_blank">voir le rapport 2021 de durabilité de l’ERAFP</a>.',
          },
        ],
      },
      subtitle:
        '<b>Depuis sa création, l’ERAFP porte un intérêt particulier aux enjeux liés au climat.</b> En&nbsp;2021, la démarche de l’ERAFP en matière de prise en compte de ces enjeux a connu un tournant décisif puisque dans le cadre de son engagement au sein de l’&nbsp;Alliance des investisseurs pour la neutralité carbone&nbsp;(AOA), l’établissement a formalisé dans une feuille de route ses objectifs à horizon&nbsp;2025 en vue d’atteindre la neutralité carbone de son portefeuille d’investissement en&nbsp;2050.',
    },
    b12goals: {
      title: 'Les types d’objectifs du protocole de fixation des objectifs à horizon 2025 de l’AOA',
      goal1: 'Objectifs d’engagement',
      goal1popupTrigger: 'popupGoalEngagement',
      goal2: 'Objectifs sectoriels',
      goal2popupTrigger: 'popupGoalSectors',
      goal3: 'Objectifs d’émission',
      goal3popupTrigger: 'popupGoalEmission',
      goal4: 'Objectifs de financements',
      goal4popupTrigger: 'popupGoalFinance',
      goal5: '<span class="number">1,5°C</span>Zone émissions nettes d’ici 2050 Impact sur le monde réel',
      subtitle:
        'Conformément au Protocole de l’Alliance, l’ERAFP s’est ainsi fixé des objectifs&nbsp;: <span class="goals"><b>D’ÉMISSIONS – D’ENGAGEMENT – DE FINANCEMENT</b></span><br/>En plus de s’être fixé des objectifs sur trois de ces quatre piliers, l’ERAFP a choisi d’en établir un supplémentaire dit «&nbsp;<b>d’alignement de température</b>&nbsp;», commun à ses portefeuilles d’actions, d’obligations privées et d’obligations convertibles.',
    },
    b13ca: {
      subtitle: 'LE RÔLE DU CONSEIL D’ADMINISTRATION DANS L’ÉLABORATION DE LA FEUILLE DE ROUTE CLIMAT',
      banner: 'b13-ca.png',
      intro:
        'L’implication du conseil d’administration dans l’élaboration de la feuille de route climat de l’ERAFP à horizon 2025 a été forte et s’est déroulée en plusieurs étapes&nbsp;:',
      desc1: [
        {
          text: '<b>•&nbsp;8 juin 2021&nbsp;:</b> formation sur l’évaluation de la performance extra-financière des entreprises <br/><b>•&nbsp;9 juin 2021&nbsp;:</b> séminaire consacré à la présentation des travaux préparatoires à l’élaboration de la feuille de route',
        },
      ],
      desc2: [
        {
          text: '<b>•&nbsp;juin et septembre 2021&nbsp;:</b> travaux menés par le CSPP sur la feuille de route <br/><b>•&nbsp;8 juillet 2021&nbsp;:</b> Présentation de la feuille de route en conseil d’administration <br/><b>•&nbsp;7 octobre 2021&nbsp;:</b> adoption de la feuille de route par le conseil d’administration',
        },
      ],
    },
  },
  components: {
    fixedColumn: {
      title: 'LES PUBLICATIONS DE L’ERAFP',
      logo: 'logo-rafp.svg',
      btnDownload: 'TÉLÉCHARGEZ<br/>LE RAPPORT ANNUEL',
      btnUrlPdf: 'https://rafp.fr/sites/default/files/2022-06/RAFP-RA-2021.pdf',
      btnDownloadPdf: 'RAFP-RA-2021.pdf',
      btnLink: 'DÉCOUVRIR<br/>LE RAPPORT DURABILITÉ',
      btnUrlLink: 'https://rafp.fr/sites/default/files/2022-06/RAFP-Rapport-durabilite-2021.pdf',
      btnLinkPdf: 'RAFP-Rapport-durabilite-2021.pdf',
    },
    footer: {
      phoneLabel: 'TÉLÉPHONE',
      phone: '+(33) 1 58 50 96 36',
      addressLabel: 'ADRESSE',
      addressStreet: '12, rue Portalis',
      addressPO: 'CS 40 007',
      addressCity: '75381 Paris Cedex 08',
      mailLabel: 'MAIL',
      mail: 'communication@erafp.fr',
      socialLabel: 'NOUS SUIVRE SUR ',
      twitterLogo: 'twitter.png',
      twitter: '@_ERAFP_',
      logo: 'logo-rafp.svg',
      luciole: 'Design LUCIOLE 2022',
    },
    menu: {
      chapters: [
        {
          title: 'REGARDS CROISÉS',
          anchor: 'crossedlooks',
        },
        {
          title: 'CHIFFRES CLÉS',
          anchor: 'numbers',
        },
        {
          title: 'FAITS MARQUANTS',
          anchor: 'facts',
        },
        {
          title: 'LES MISSIONS DU RAFP',
          anchor: 'missions',
        },
        {
          title: 'COTISATIONS & PRESTATIONS',
          anchor: 'subscriptions',
        },
        {
          title: 'UNE INFORMATION PLUS FINE DES PARTIES PRENANTES',
          anchor: 'info',
        },
        {
          title: 'UNE POLITIQUE ISR RENFORCÉE',
          anchor: 'isr',
        },
        {
          title: 'UN INVESTISSEUR ACTIF FACE AU CHANGEMENT CLIMATIQUE',
          anchor: 'investor',
        },
      ],
      btnDownload: 'TÉLÉCHARGEZ LA VERSION COMPLÈTE DU RAPPORT ANNUEL DE RAFP',
      btnUrlPdf: 'https://rafp.fr/sites/default/files/2022-06/RAFP-RA-2021.pdf',
      btnDownloadPdf: 'RAFP-RA-2021.pdf',
      btnLink: 'DÉCOUVRIR LE RAPPORT DURABILITÉ',
      btnUrlLink: 'https://rafp.fr/sites/default/files/2022-06/RAFP-Rapport-durabilite-2021.pdf',
      btnLinkPdf: 'RAFP-Rapport-durabilite-2021.pdf',
    },
  },
  popups: {
    popupChallengeEnvironment: {
      title: 'DÉFIS EN TERMES D’ENVIRONNEMENT ET DE CHANGEMENT CLIMATIQUE',
      desc1: [
        'Dans son sixième rapport, publié le 9&nbsp;août&nbsp;2021 , le Groupe d’experts intergouvernemental sur l’évolution du climat (GIEC) réalise des estimations particulièrement alarmantes : la température moyenne de la planète a augmenté d’1,1&nbsp;°C depuis le début de l’ère industrielle, «&nbsp;un niveau de réchauffement qui n’a jamais été observé depuis au moins 2&nbsp;000&nbsp;ans&nbsp;», précise-t-il. Ainsi, même dans les hypothèses les plus favorables, le seuil d’1,5&nbsp;°C de réchauffement, qui permettrait de limiter l’ampleur des conséquences du dérèglement climatique, pourrait être atteint dès 2030, soit dix ans plus tôt qu’anticipé. Conscient de l’urgence de la situation, l’ERAFP, dans son rôle d’investisseur, cherche à inciter les entreprises à prendre en compte l’impact environnemental de leurs produits ou services, à maîtriser les risques associés au changement climatique, à adopter une stratégie 1,5&nbsp;°C et à contribuer à la transition énergétique. Pour ce faire, il agit aux différentes étapes de la décision d’investissement&nbsp;: en amont, au moment de la sélection des investissements (via l’inclusion de critères d’analyse spécifiques), mais aussi en aval notamment en dialoguant avec les entreprises dans le cadre d’une démarche d’engagement structurée.',
      ],
    },
    popupChallengeLeadership: {
      title: 'DÉFIS EN TERMES DE GOUVERNANCE',
      desc1: [
        'Pour l’ERAFP, l’analyse de la gouvernance est essentielle en ce qu’elle permet d’évaluer la responsabilité de l’entreprise vis-à-vis de ses parties prenantes. L’ERAFP cherche à promouvoir les entreprises ayant une gouvernance qui intègre l’équilibre des pouvoirs, l’efficacité des mécanismes de contrôle, une politique de rémunération responsable et qui respecte la parité femmes-hommes. La qualité de la gouvernance permet de répondre aux défis en termes de lutte contre la corruption et de blanchiment d’argent, de protection et de respect des droits du client, de transparence et de responsabilité en matière fiscale.',
      ],
      desc2: [
        '<span class= "quote" >«&nbsp; En tant qu’établissement public institué au bénéfice des fonctionnaires de l’État, territoriaux et hospitaliers, ainsi que des magistrats, l’ERAFP est voué à l’intérêt général&nbsp;»</>',
      ],
      desc3: [''],
    },
    popupChallengeSocial: {
      title: 'DÉFIS EN TERMES SOCIAUX',
      desc1: [
        'L’identité et la composition mêmes du conseil d’administration de l’ERAFP font de la dimension sociale un aspect fondamental, avec 8 représentants des bénéficiaires cotisants proposés par les organisations syndicales représentatives, 8 représentants des employeurs et 3 personnalités qualifiées. En tant qu’établissement public français, l’ERAFP entend préserver les acquis sociaux au travers de la promotion du dialogue social et du respect du droit syndical. L’ERAFP souhaite également promouvoir l’état de droit et les droits de l’Homme pour ses investissements souverains, mais aussi pour ses investissements privés.',
      ],
      desc2: [
        'Dans un contexte de crise sanitaire mondiale, l’Établissement attend des entreprises qu’elles soient particulièrement vigilantes sur le respect des droits humains et du travail dans leur chaîne d’approvisionnement et chez leurs sous-traitants. De même, les défis que les entreprises vont devoir relever pour réussir la transition énergétique impliquent des transformations majeures dans certains secteurs d’activités, avec des impacts sur les salariés et la société civile. L’ERAFP attend des entreprises que leur stratégie de transition intègre les principes d’une transition juste.',
      ],
      desc3: [''],
    },
    popupDominiqueLamiot: {
      desc: '« L’année 2021 aura été encore marquée par la pandémie et ses répercussions sur l’économie, mais le conseil d’administration du RAFP a poursuivi ses travaux de façon sereine, fort de ses acquis antérieurs.&nbsp;»',
      role: '<b>DOMINIQUE LAMIOT</b>, PRÉSIDENT DE L’ERAFP',
    },
    popupGoalEngagement: {
      title: 'Objectifs d’engagements',
      desc: [
        '•&nbsp;Engagement avec 20 sociétés les plus carbo-intensives, ou celles correspondant à 65&nbsp;% des émissions du portefeuille.',
        '•&nbsp;Contribution&nbsp;:<br/> - à l’engagement avec des sociétés sur les secteurs-cibles&nbsp;;<br/> - à au moins une action d’engagement avec les 4 principales sociétés de gestion&nbsp;;<br/> - aux prises de position de l’Alliance.',
        '•&nbsp;Promotion de politiques climatiques.',
      ],
    },
    popupGoalSectors: {
      title: 'Objectifs sectoriels',
      desc: [
        '•&nbsp;Réduction des intensités carbone sur les secteurs prioritaires de l’Alliance (pétrole et gaz, services aux collectivités, acier et transport).',
        '•&nbsp;Inclusion du scope 3 quand cela est possible.',
        '•&nbsp;Utilisation d’indicateurs spécifiques aux secteurs recommandés.<br/>',
        '•&nbsp;Utilisation des trajectoires sectorielles pour la définition des objectifs.',
      ],
    },
    popupGoalEmission: {
      title: 'Objectifs d’émission du portefeuille',
      desc: [
        '•&nbsp;-16&nbsp;% à -29&nbsp;% d’ici 2025 sur les actions et obligations cotées. Sur l’immobilier sont recommandés des objectifs similaires et/ou basés sur les trajectoires CRREM nationales.',
        '•&nbsp;Portant sur les émissions scopes 1 et 2 des portefeuilles et suivi du scope 3.',
        '•&nbsp;Recommandation d’utiliser une réduction par rapport à 2019, basée sur les émissions absolues ou en intensité.',
      ],
    },
    popupGoalFinance: {
      title: 'Objectifs de financement de la transition',
      desc: [
        '•&nbsp;Reporter sur la progression des investissements en faveur du climat.',
        '•&nbsp;Se focaliser (entre autres) sur les énergies renouvelables dans les marchés émergents, les bâtiments verts, les forêts durables et l’hydrogène vert.',
        '•&nbsp;Contribuer aux activités permettant l’élargissement de l’univers d’investissement «&nbsp;bas carbone&nbsp;» et à la construction de solutions.',
      ],
    },
    popupLaurentGalzy: {
      desc: '« Avec l’adoption d’une feuille de route pour 2021-2025, l’ERAFP s’est fixé de nouveaux objectifs dans le cadre de l’Alliance des investisseurs pour la neutralité carbone et signe ainsi une année d’approfondissement de sa politique en matière de transition énergétique.&nbsp;»',
      role: '<b>LAURENT GALZY</b>, DIRECTEUR DE L’ERAFP',
    },
    popupMissionCompromise: {
      title: 'Concilier rendement financier et recherche d’impact',
      desc: [
        'Seul fonds de pension public français, l’ERAFP entend démontrer qu’un investisseur peut jouer un rôle au service de l’intérêt général, sans pour autant renoncer au rendement financier de ses placements.',
        'Trois domaines d’intervention spécifiques ont ainsi été ciblés par l’Établissement&nbsp;: la contribution à la transition vers une économie décarbonée, le soutien à l’activité économique et à l’emploi et le financement du logement accessible au bénéfice des agents publics.',
      ],
    },
    popupMissionSupplement: {
      title: 'Apporter un supplément de retraite aux fonctionnaires',
      desc: 'Grâce au Régime, près de 4,5&nbsp;millions d’agents cotisants bénéficient d’un supplément de retraite. Leurs cotisations, assises principalement sur les primes, sont complétées par celles des quelques 42858&nbsp;employeurs publics. Au total, elles ont représenté un montant de 1,96 Md€ encaissés en&nbsp;2021.',
    },
    popupMissionValues: {
      title: 'Promouvoir les valeurs du service public via l’investissement socialement responsable',
      desc: [
        'L’ERAFP est un établissement public qui gère un régime de retraite au bénéfice des fonctionnaires. Cette particularité en fait un acteur voué à l’intérêt général et portant une forte culture des enjeux sociaux.',
        'Il a ainsi élaboré, dès sa création, une démarche ambitieuse pour mettre en œuvre une politique d’investissement socialement responsable (ISR), fondée sur les valeurs du service public. Elle tient compte de critères sociaux, environnementaux et de bonne gouvernance pour l’ensemble des placements du Régime.',
      ],
    },
    popupValueHR: {
      title: 'État de droit et droits de l’homme',
      desc: [
        '•&nbsp;non-discrimination et promotion de l’égalité des chances',
        '•&nbsp;liberté d’opinion et d’expression et autres droits fondamentaux ',
        '•&nbsp;gestion responsable de la chaîne d’approvisionnement',
      ],
    },
    popupValueProgress: {
      title: 'Procès social',
      desc: [
        '•&nbsp;gestion responsable des carrières et anticipation en matière d’emploi',
        '•&nbsp;partage équitable de la valeur ajoutée',
        '•&nbsp;amélioration des conditions de travail',
        '•&nbsp;impact et valeur ajoutée sociale du produit ou service',
      ],
    },
    popupValueDemocracy: {
      title: 'Démocratie social',
      desc: [
        '•&nbsp;respect du droit syndical et promotion du dialogue social',
        '•&nbsp;amélioration des conditions de santé-sécurité',
      ],
    },
    popupValueEnvironment: {
      title: 'Environnement',
      desc: [
        '•&nbsp;stratégie en matière d’environnement',
        '•&nbsp;impact environnemental du produit ou service',
        '•&nbsp;maîtrise des impacts environnementaux',
        '•&nbsp;maîtrise des risques associés au changement climatique et contribution à la transition énergétique',
      ],
    },
    popupValueLeadership: {
      title: 'Bonne gouvernance et transparence',
      desc: [
        '•&nbsp;direction — gouvernance d’entreprise',
        '•&nbsp;protection et respect des droits du client/consommateur',
        '•&nbsp;lutte contre la corruption et le blanchiment d’argent',
        '•&nbsp;pratiques responsables en matière de lobbying',
        '•&nbsp;transparence et responsabilité en matière fiscale',
      ],
    },
  },
};

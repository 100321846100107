import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import Stack from '@mui/material/Stack';
import Hidden from '@mui/material/Hidden';
import { withStyles } from '@mui/styles';
import { Typography } from '@mui/material/';
import Anchor from '../../components/Anchor';

import styles from './styles';
import data from '../../utils/data';

class B04facts extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b04facts;
    this.imgBase = `${this.props.baseUrl}/blocks/`;

    this.renderDate = this.renderDate.bind(this);
  }

  renderDate(event, key) {
    const { classes } = this.props;
    return (
      <Stack key={key} direction="column" className={classes.event}>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end" className={classes.eventTitle}>
          <Typography variant="subtitle1" className={classes.date}>
            {parse(event.date)}
          </Typography>
          <Hidden mdDown>
            <img className={classes.illustration} src={`${this.imgBase}/${event.image}`} alt=""></img>
          </Hidden>
        </Stack>
        <Typography variant="button" sx={{ textTransform: 'initial' }} className={classes.desc}>
          {parse(event.desc)}
        </Typography>
      </Stack>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Stack direction="row" className={classes.blockContainer}>
        <Anchor id="facts" />
        <Hidden mdDown>
          <img className={classes.banner} src={`${this.imgBase}/${this.dataBlock.banner}`} alt=""></img>
        </Hidden>
        <Hidden lgUp>
          <img className={classes.bannerMobile} src={`${this.imgBase}/${this.dataBlock.bannerMobile}`} alt=""></img>
        </Hidden>
        <Stack direction="column">
          <Typography variant="h1" className={classes.articleTitle}>
            {parse(this.dataBlock.title)}
          </Typography>
          <Stack
            sx={{ flexDirection: { lg: 'row', md: 'row' } }}
            justifyContent="space-around"
            alignItems="baseline"
            className={classes.events}
          >
            {this.dataBlock.dates.map((date, key) => this.renderDate(date, key))}
          </Stack>
        </Stack>
      </Stack>
    );
  }
}

B04facts.propTypes = {
  baseUrl: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(B04facts);

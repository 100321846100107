import { createTheme } from '@mui/material/styles';
const BASE_URL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? '' : process.env.PUBLIC_URL;

const theme = createTheme({
  url: s => `${BASE_URL}${s}`,
  palette: {
    primary: {
      main: '#11337F',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#FFF',
      contrastText: '#000',
    },
    white: {
      main: '#FFF',
      contrastText: '#000',
    },
    black: {
      main: '#000',
      contrastText: '#FFF',
    },
    grey: {
      main: '#707070',
      light: '#555555',
      contrastText: '#000',
    },
    beige: {
      main: '#F0EBD5',
      contrastText: '#000',
    },
    gold: {
      main: '#C09551',
      light: '#BA9857',
      contrastText: '#FFF',
    },
    green: {
      main: '#14492d',
      light: '#15482E',
      dark: '#24482F',
      contrastText: '#FFF',
    },
    lime: {
      main: '#B8D590',
      light: '#C7D395',
      dark: '#A4BC82',
      contrastText: '#FFF',
    },
  },
  typography: {
    fontFamily: 'Lato, Commissioner, Bodoni Moda, sans-serif',
    h1: {
      fontFamily: 'Commissioner, sans-serif',
      fontWeight: 100,
      textTransform: 'uppercase',
      lineHeight: 1.38,
      letterSpacing: 0,
      fontSize: '3.44rem',
    },
    h2: {
      fontFamily: 'Bodoni Moda, serif',
      fontWeight: 700,
      textTransform: 'none',
      lineHeight: 1.11,
      letterSpacing: 0,
      fontSize: '2.25rem',
    },
    h3: {
      fontFamily: 'Commissioner, sans-serif',
      fontWeight: 700,
      textTransform: 'none',
      lineHeight: 1.33,
      fontSize: ' 1.88rem',
    },
    h4: {
      fontFamily: 'Commissioner, sans-serif',
      fontWeight: 500,
      textTransform: 'none',
      lineHeight: 1.7,
      fontSize: '1.69rem',
    },
    h5: {
      fontFamily: 'Commissioner, sans-serif',
      fontWeight: 100,
      lineHeight: 1.38,
      letterSpacing: '0em',
      fontSize: '1.13rem',
    },
    h6: {
      fontFamily: 'Lato, sans-serif',
      fontWeight: 700,
      lineHeight: 1.56,
      letterSpacing: '0em',
      fontSize: '1rem',
    },
    body1: {
      fontFamily: 'Commissioner, sans-serif',
      fontWeight: 400,
      lineHeight: 1.45,
      letterSpacing: 0,
      fontSize: '1.38rem',
    },
    body2: {
      fontFamily: 'Commissioner, sans-serif',
      fontWeight: 300,
      lineHeight: 1.5,
      letterSpacing: 0,
      fontSize: '1rem',
    },
    subtitle1: {
      fontFamily: 'Bodoni Moda, serif',
      fontWeight: 600,
      lineHeight: 1.38,
      letterSpacing: 0,
      fontSize: '1.63rem',
    },
    subtitle2: {
      fontFamily: 'Bodoni Moda, serif',
      fontWeight: 300,
      lineHeight: 1.35,
      letterSpacing: 0,
      fontSize: '1.07rem',
    },
    button: {
      fontFamily: 'Commissioner, sans-serif',
      fontWeight: 400,
      lineHeight: 1.375,
      letterSpacing: 0,
      textTransform: 'uppercase',
      fontSize: '1rem',
    },
    caption: {
      fontFamily: 'Commissioner, sans-serif',
      fontWeight: 400,
      lineHeight: 1.57,
      letterSpacing: 0,
      textTransform: 'none',
      fontSize: '0.9rem',
    },
    overline: {
      fontFamily: 'Commissioner, sans-serif',
      fontWeight: 300,
      lineHeight: 1.33,
      letterSpacing: 0,
      textTransform: 'none',
      fontSize: '1.2rem',
    },
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0, // < 600
      sm: 600, // < 960
      md: 960, //  < 1280
      lg: 1088, // < 1920
      xl: 1920, // -
    },
  },
});

export default theme;

function loop(theme, res, list, type) {
  const suffixes = { main: '', light: 'light', dark: 'dark' };
  const suffixe = suffixes[type];
  list.forEach(key => {
    res[`${key}${suffixe}`] = {
      backgroundColor: `${theme.palette[key][type]} !important`,
    };
    res[`title-${key}${suffixe}`] = {
      color: `${theme.palette[key][type]} !important`,
    };
    res[`text-${key}${suffixe}`] = {
      color: `${theme.palette[key][type]} !important`,
    };
  });
}

export default theme => {
  const main = ['white', 'black', 'grey', 'beige', 'gold', 'green', 'lime'];
  const light = ['grey', 'gold', 'green', 'lime'];
  const dark = ['green', 'lime'];

  const res = {
    dialogPaper: {
      overflow: 'initial !important',
      padding: theme.spacing(5, 12),
      maxHeight: 800,
      borderRadius: 400,
      [theme.breakpoints.down('sm')]: {
        maxHeight: 'initial',
        padding: `${theme.spacing(12, 1)}`,
        margin: theme.spacing(2),
      },
      '& > *': {
        position: 'relative',
      },
    },
    closeIcon: {
      position: 'absolute !important',
      top: 10,
      right: 10,
      backgroundColor: 'transparent !important',
      border: `solid 2px ${theme.palette.white.main} !important`,
      height: '30px !important',
      minHeight: '30px !important',
      width: '30px !important',
      '& svg': {
        height: '20px !important',
        width: '20px !important',
        stroke: theme.palette.white.main,
      },
    },
    dialogContent: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      '& > *': {
        width: '100%',
      },
      '&.twoColumns > *': {
        '&:first-child': {
          paddingRight: theme.spacing(2),
          [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
          },
        },
        '&:last-child': {
          paddingLeft: theme.spacing(5),
          [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(3),
          },
        },
        '& > *': {
          paddingBottom: theme.spacing(2),
        },
      },
    },
    title: {
      textAlign: 'center',
      fontWeight: 400,
      textTransform: 'none',
      maxWidth: '90%',
      margin: 'auto !important',
    },
    role: {
      textAlign: 'center',
      display: 'block',
      maxWidth: '65%',
      margin: 'auto !important',
    },
    desc: {
      position: 'relative',
      padding: theme.spacing(2),
      paddingBottom: 0,
      '& .list': {
        display: 'block',
        margin: '1% 1%',
      },
    },
    center: {
      textAlign: 'center',
    },
  };

  loop(theme, res, main, 'main');
  loop(theme, res, light, 'light');
  loop(theme, res, dark, 'dark');

  return res;
};

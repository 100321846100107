import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { withStyles } from '@mui/styles';
import { Dialog, /* DialogTitle, */ DialogContent, Fab, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './styles';

class PopupRounded extends React.Component {
  renderSectionColumn(content) {
    const { classes } = this.props;
    return (
      <div>
        {typeof content === 'string' && (
          <Typography key="content" variant="body1" className={classes.desc}>
            {parse(content)}
          </Typography>
        )}
        {Array.isArray(content) &&
          content.map((c, idx) => (
            <Typography key={idx} variant="body1" className={classes.desc}>
              {parse(c)}
            </Typography>
          ))}
      </div>
    );
  }

  render() {
    const { popupData, classes, color, colorText, colorTitle, centerText, open, onClose } = this.props;
    const textColor = `text-${colorText}`;
    const titleColor = `title-${colorTitle}`;
    return (
      <Dialog
        maxWidth="md"
        fullWidth
        scroll="body"
        open={open || false}
        onClose={onClose}
        PaperProps={{
          square: true,
          className: `${classes.dialogPaper} ${classes[color]} ${classes[titleColor]}`,
        }}
      >
        <Fab color="primary" onClick={onClose} className={classes.closeIcon}>
          <CloseIcon fontSize="large" />
        </Fab>
        {popupData.title && (
          <Typography variant="subtitle1" className={`${classes.title} ${classes.titleColor}`}>
            {parse(popupData.title)}
          </Typography>
        )}
        <DialogContent className={`${classes.dialogContent} ${classes[textColor]}`}>
          <div className={centerText ? classes.center : ''}>{this.renderSectionColumn(popupData.desc)}</div>
        </DialogContent>
        {popupData.role && (
          <Typography variant="caption" className={`${classes.role} ${classes.titleColor}`}>
            {parse(popupData.role)}
          </Typography>
        )}
      </Dialog>
    );
  }
}

PopupRounded.propTypes = {
  popupData: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  title: PropTypes.node,
  color: PropTypes.string,
  colorText: PropTypes.string,
  centerText: PropTypes.bool,
  colorTitle: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

PopupRounded.defaultProps = {
  color: 'black',
  centerText: true,
};

export default withStyles(styles)(PopupRounded);

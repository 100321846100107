export default theme => ({
  blockContainer: {
    position: 'relative',
    backgroundColor: theme.palette.white.main,
    padding: `${theme.spacing(9)} calc(8vw) ${theme.spacing(9)} ${theme.spacing(7)}`,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
  },
  title: {
    margin: 'auto',
    textIndent: 96,
    lineHeight: '60px !important',
    color: theme.palette.gold.main,
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem !important',
      textIndent: 0,
      textAlign: 'center',
      lineHeight: '40px !important',
    },
  },
  subtitle: {
    fontWeight: 'bold !important',
    fontFamily: '"Bodina Moda" !important',
    color: theme.palette.gold.main,
  },
  left: {
    flex: 1,
    '& a': {
      textDecoration: 'none',
    },
  },
  right: {
    flex: 2,
    [theme.breakpoints.down('md')]: {
      marginLeft: '0 !important',
      marginTop: '20px !important',
    },
  },
  desc: {
    textAlign: 'right',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    color: theme.palette.grey.light,
  },
  textTitle: {
    maxWidth: 350,
    fontWeight: 'bold !important',
    paddingBottom: theme.spacing(2),
    color: theme.palette.green.main,
  },
  text: {
    fontWeight: '300 !important',
    paddingBottom: theme.spacing(3),
    '& .paragraph': {
      paddingBottom: theme.spacing(1),
      '&:after': {
        content: "''",
        display: 'block',
        height: 0,
        width: '100%',
      },
    },
  },
  flexColumn: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column !important',
      marginLeft: '0',
    },
  },
});

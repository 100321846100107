import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { withStyles } from '@mui/styles';
import { Stack, Typography } from '@mui/material/';

import styles from './styles';
import data from '../../utils/data';

class Footer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dataBlock = data.components.footer;
    this.imgBase = `${this.props.baseUrl}/common/`;
  }

  render() {
    const { classes /* , baseUrl */ } = this.props;
    return (
      <div className={classes.blockContainer}>
        <Stack direction={{ xs: 'column', lg: 'row' }} spacing={0}>
          <Stack direction="column" className={classes.center}>
            <div className={`${classes.top} center`}></div>
            <div className={`${classes.mainMargin} center`}></div>
            <Stack direction="column" className={classes.main}>
              <Stack direction="row" className={classes.topMain} spacing={0}>
                <div className={classes.leftTopMain}></div>
                <div className={classes.rightTopMain}></div>
              </Stack>
              <Stack direction={{ xs: 'column', lg: 'row' }} className={classes.bottomMain} spacing={0}>
                <Stack direction="column" justifyContent="space-around" className={classes.leftBottomMain}>
                  <Stack direction="column" className={classes.topInfo}>
                    <Typography variant="caption" className={classes.title}>
                      {parse(this.dataBlock.phoneLabel)}
                    </Typography>
                    <Typography variant="caption" className={classes.text}>
                      {parse(this.dataBlock.phone)}
                    </Typography>
                  </Stack>
                  <Stack direction="column" className={classes.bottomInfo}>
                    <Typography variant="caption" className={classes.title}>
                      {parse(this.dataBlock.addressLabel)}
                    </Typography>
                    <Typography variant="caption" className={classes.text}>
                      {parse(this.dataBlock.addressStreet)}
                    </Typography>
                    <Typography variant="caption" className={classes.text}>
                      {parse(this.dataBlock.addressPO)}
                    </Typography>
                    <Typography variant="caption" className={classes.text}>
                      {parse(this.dataBlock.addressCity)}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="column" justifyContent="space-around" className={classes.rightBottomMain}>
                  <Stack direction="column" className={classes.topInfo}>
                    <Typography variant="caption" className={classes.title}>
                      {parse(this.dataBlock.mailLabel)}
                    </Typography>
                    <Typography variant="caption" className={classes.text}>
                      {parse(this.dataBlock.mail)}
                    </Typography>
                  </Stack>
                  <Stack direction="column" className={classes.bottomInfo}>
                    <Typography variant="caption" className={classes.title}>
                      {parse(this.dataBlock.socialLabel)}
                    </Typography>
                    <a href="https://twitter.com/_erafp_" target="_blank" className={classes.twitter}>
                      <Typography variant="caption">
                        <img
                          className={classes.image}
                          src={`${this.imgBase}/${this.dataBlock.twitterLogo}`}
                          alt=""
                        ></img>
                        {parse(this.dataBlock.twitter)}
                      </Typography>
                    </a>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction={{ xs: 'column', lg: 'row' }} className={`${classes.medium} center`}>
              <div className={classes.leftMedium}>
                <Typography variant="caption" className={classes.text}>
                  {parse(this.dataBlock.luciole)}
                </Typography>
              </div>
              <div className={classes.rightMedium}></div>
            </Stack>
            <Stack direction={{ xs: 'column', lg: 'row' }} className={`${classes.footer} center`}>
              <div className={classes.leftMedium}></div>
              <div className={classes.rightMedium}></div>
            </Stack>
          </Stack>
          <Stack direction="column" className={classes.right}>
            <div className={`${classes.top} right`}></div>
            <div className={`${classes.mainMargin} right`}></div>
            <Stack direction="column" className={`${classes.main} ${classes.rightMain} right`}>
              <div className={classes.topMain}></div>
              <Stack direction="column" justifyContent="space-between" className={classes.rightMainBottom}>
                <img className={classes.logo} src={`${this.imgBase}${this.dataBlock.logo}`} alt="" />
              </Stack>
            </Stack>
            <div className={`${classes.medium} right`}></div>
            <div className={`${classes.footer} right`}></div>
          </Stack>
        </Stack>
      </div>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
};

export default withStyles(styles)(Footer);

export default theme => ({
  blockContainer: {
    backgroundColor: theme.palette.beige.main,
    position: 'relative',
    padding: `${theme.spacing(3)} calc(8vw) ${theme.spacing(12)} ${theme.spacing(7)}`,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
  },
  articleTitle: {
    lineHeight: '52px !important',
    backgroundColor: theme.palette.beige.main,
    color: theme.palette.gold.main,
    zIndex: 1,
    textIndent: -80,
    paddingLeft: theme.spacing(14),
    paddingRight: theme.spacing(4),
    position: 'relative',
    top: 50,
    [theme.breakpoints.down('md')]: {
      fontSize: '2.3rem !important',
      textIndent: 0,
      textAlign: 'center',
      lineHeight: '40px !important',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  content: {
    position: 'relative',
    color: theme.palette.green.main,
    width: 900,
    height: 600,
    borderTopLeftRadius: 300,
    borderTopRightRadius: 300,
    borderBottomLeftRadius: 300,
    borderBottomRightRadius: 300,
    border: `1px solid ${theme.palette.gold.main}`,
    [theme.breakpoints.down('md')]: {
      width: 280,
      height: 1000,
    },
  },
  number: {
    position: 'relative',
    maxWidth: 200,
    fontFamily: 'Bodoni Moda, serif !important',
    marginTop: '0 !important',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginTop: `${theme.spacing(3)} !important`,
    },
  },
  numberTitle: {
    color: theme.palette.gold.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8rem !important',
    },
  },
  desc: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9rem !important',
    },
  },
  prefix: {
    position: 'absolute',
    top: -24,
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import Stack from '@mui/material/Stack';
import Hidden from '@mui/material/Hidden';
import { withStyles } from '@mui/styles';
import { IconButton, Typography } from '@mui/material/';
import { ReactComponent as Add } from '../../res/add.svg';
import PopupRounded from '../../popups/PopupRounded';
import Anchor from '../../components/Anchor';
import styles from './styles';
import data from '../../utils/data';

class B02crossedlooks extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b02crossedlooks;
    this.dataPopups = data.popups;
    this.imgBase = `${this.props.baseUrl}/blocks/`;
  }

  render() {
    const { classes, onPopup, popupsState } = this.props;
    const title = parse(this.dataBlock.title);

    return (
      <Stack direction="row" className={classes.blockContainer}>
        <Anchor id="crossedlooks" />
        <Hidden mdDown>
          <Stack
            sx={{
              backgroundImage: `url(${this.imgBase}/${this.dataBlock.banner})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '12vw',
            }}
          ></Stack>
        </Hidden>
        <Stack direction="column" className={classes.content}>
          <Typography variant="h1" className={classes.title}>
            {title}
          </Typography>
          <Stack
            direction={{ xs: 'column', lg: 'row' }}
            justifyContent="center"
            alignItems="center"
            className={classes.main}
          >
            <div className={classes.mainWrapper}>
              <Hidden mdDown>
                <img className={classes.photo} src={`${this.imgBase}/${this.dataBlock.photo}`} alt=""></img>
              </Hidden>
              <Hidden lgUp>
                <img className={classes.photoTop} src={`${this.imgBase}/${this.dataBlock.photoTop}`} alt=""></img>
              </Hidden>
              <Stack direction="column" alignItems="flex-start" className={classes.leftContent}>
                <Typography className={classes.subtitle} variant="subtitle1">
                  {parse(this.dataBlock.desc1)}
                </Typography>
                <Typography className={classes.names} variant="body2">
                  {parse(this.dataBlock.name1)}
                </Typography>
                <IconButton
                  onClick={() => onPopup(this.dataBlock.dominiqueLamiotPopupTrigger, true)}
                  className={classes.btn}
                >
                  <Add />
                </IconButton>
              </Stack>
              <Hidden lgUp>
                <img className={classes.photoBottom} src={`${this.imgBase}/${this.dataBlock.photoBottom}`} alt=""></img>
              </Hidden>
              <Stack direction="column-reverse" alignItems="flex-start" className={classes.rightContent}>
                <IconButton
                  onClick={() => onPopup(this.dataBlock.laurentGalzyPopupTrigger, true)}
                  className={classes.btn}
                >
                  <Add />
                </IconButton>
                <Typography className={classes.names} variant="body2">
                  {parse(this.dataBlock.name2)}
                </Typography>
                <Typography className={classes.subtitle} variant="subtitle1">
                  {parse(this.dataBlock.desc2)}
                </Typography>
              </Stack>
            </div>
          </Stack>
        </Stack>
        <PopupRounded
          color="beige"
          colorText="greylight"
          colorTitle="green"
          popupData={this.dataPopups.popupDominiqueLamiot}
          open={!!popupsState.popupDominiqueLamiot}
          onClose={() => onPopup('popupDominiqueLamiot', false)}
        ></PopupRounded>
        <PopupRounded
          color="beige"
          colorText="greylight"
          colorTitle="green"
          popupData={this.dataPopups.popupLaurentGalzy}
          open={!!popupsState.popupLaurentGalzy}
          onClose={() => onPopup('popupLaurentGalzy', false)}
        ></PopupRounded>
      </Stack>
    );
  }
}

B02crossedlooks.propTypes = {
  baseUrl: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onPopup: PropTypes.func,
  popupsState: PropTypes.object,
};

export default withStyles(styles)(B02crossedlooks);

export default theme => ({
  page: {
    position: 'relative',
    width: '100%',
    overflowX: 'hidden',
    '&:after': {
      content: '""',
      display: 'block',
      position: 'fixed',
      zIndex: 200,
      top: 0,
      right: '6vh',
      height: '100%',
    },
    [theme.breakpoints.down('md')]: {
      height: '100%',
      overflowY: 'visible',
    },
  },
  content: {
    width: '75vw',
    height: '100vh',
    position: 'relative',
    left: '25vw',
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      left: 'initial',
    },
  },
  burger: {
    position: 'fixed',
    top: 'calc(3vh + 30px)',
    right: 'calc(6vh + 30px)',
    zIndex: 75,
    width: 35,
    opacity: 1,
    pointerEvents: 'all',
    transition: 'opacity 0.3s ease-in-out',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('md')]: {
      width: 25,
      top: theme.spacing(4),
      right: theme.spacing(4),
    },
  },
  close: {
    position: 'fixed',
    zIndex: 75,
    width: 25,
    top: theme.spacing(4),
    right: theme.spacing(4),
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.3s ease-in-out',
  },
  visible: {
    opacity: 1,
    pointerEvents: 'all',
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
  bg: {
    [theme.breakpoints.down('md')]: {
      backgroundSize: '100vw 100% !important',
      backgroundPosition: '0 !important',
      width: '100vw !important',
    },
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@mui/material/ButtonBase';
import { withStyles } from '@mui/styles';
import { ReactComponent as Chevron } from '../../res/rightchevron.svg';

import styles from './styles';

class Button extends React.PureComponent {
  renderIconChevron(iconClass) {
    const { classes, underline, color } = this.props;
    return <Chevron className={`${classes[iconClass]} ${underline ? classes[`${color}-iconUnderline`] : ''}`} />;
  }

  render() {
    const { children, classes, className, color, size, inverse, underline, noIcon, noBorder, ...other } = this.props;
    let colorClass = inverse ? `${color}-inverse` : color;
    colorClass = underline ? `${color}-underline` : colorClass;
    const labelClass = underline ? classes[`${color}-labelUnderline`] : '';
    const iconClass = `icon-${size}`;
    return (
      <ButtonBase
        focusRipple
        className={`${classes.button} ${className || ''} ${classes[colorClass]} ${classes[size]} ${
          noBorder ? classes.noBorder : ''
        }`}
        {...other}
      >
        <span className={`${classes.label} ${labelClass} ${classes[`label-${size}`]}`}>{children}</span>
        {noIcon ? null : this.renderIconChevron(iconClass)}
      </ButtonBase>
    );
  }
}

Button.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  inverse: PropTypes.bool,
  underline: PropTypes.bool,
  noIcon: PropTypes.bool,
  noBorder: PropTypes.bool,
};

Button.defaultProps = {
  color: 'grey',
  size: 'medium',
  inverse: false,
  underline: false,
  noIcon: false,
};

export default withStyles(styles)(Button);

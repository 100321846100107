import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import Stack from '@mui/material/Stack';
import { withStyles } from '@mui/styles';
import { Typography } from '@mui/material/';
import Anchor from '../../components/Anchor';

import styles from './styles';
import data from '../../utils/data';

class B06subscriptions extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b06subscriptions;
    this.imgBase = `${this.props.baseUrl}/blocks/`;
    this.renderText = this.renderText.bind(this);
  }

  renderText(text, key) {
    const { classes } = this.props;
    return (
      <div key={key} className={classes.chapter}>
        {text.title && (
          <Typography variant="subtitle2" className={classes.textTitle}>
            {parse(text.title)}
          </Typography>
        )}
        {text.text && (
          <Typography variant="body2" className={`${classes.text} text`}>
            {parse(text.text)}
          </Typography>
        )}
      </div>
    );
  }

  renderTop() {
    const { classes } = this.props;

    return (
      <Stack direction="column" alignItems="center" className={classes.content}>
        <Typography variant="h1" className={classes.title}>
          {parse(this.dataBlock.title)}
        </Typography>
        <Stack direction="row" sx={{ pb: '32px' }} spacing={8} className={classes.content}>
          <Stack direction="column" className={classes.column}>
            {this.dataBlock.desc1.map((desc, key) => this.renderText(desc, key))}
          </Stack>
          <Stack direction="column" className={classes.column}>
            {this.dataBlock.desc2.map((desc, key) => this.renderText(desc, key))}
          </Stack>
        </Stack>
      </Stack>
    );
  }

  renderBottom() {
    const { classes } = this.props;

    return (
      <Stack direction="column" alignItems="center" className={classes.bottom}>
        <Typography variant="h3" sx={{ pt: '32px', pb: '32px' }} className={classes.subtitle}>
          {parse(this.dataBlock.encart.subtitle)}
        </Typography>
        <Stack sx={{ p: '32px' }} className={classes.aside}>
          {this.dataBlock.encart.desc.map((desc, key) => this.renderText(desc, key))}
        </Stack>
        <img className={classes.banner} src={`${this.imgBase}/${this.dataBlock.encart.banner}`} alt=""></img>
      </Stack>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Stack direction="column" className={classes.blockContainer}>
        <Anchor id="subscriptions" />
        {this.renderTop()}
        {this.renderBottom()}
      </Stack>
    );
  }
}

B06subscriptions.propTypes = {
  baseUrl: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(B06subscriptions);

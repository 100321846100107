import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Hidden from '@mui/material/Hidden';
import styles from './styles';
import data from './utils/data';

import { ReactComponent as Burger } from './res/burger.svg';
import { ReactComponent as Close } from './res/closeMenu.svg';
import Menu from './components/Menu';
import FixedColumn from './components/FixedColumn';
import B00intro from './blocks/B00intro';
import B00introMobile from './blocks/B00introMobile';
import B01retirement from './blocks/B01retirement';
import B02crossedlooks from './blocks/B02crossedlooks';
import B03numbers from './blocks/B03numbers';
import B04facts from './blocks/B04facts';
import B05missions from './blocks/B05missions';
import B06subscriptions from './blocks/B06subscriptions';
import B07info from './blocks/B07info';
import B08isr from './blocks/B08isr';
import B09values from './blocks/B09values';
import B10challenges from './blocks/B10challenges';
import B11investor from './blocks/B11investor';
import B12goals from './blocks/B12goals';
import B13ca from './blocks/B13ca';
import Footer from './components/Footer';
import FooterMobile from './components/FooterMobile';
import './index.css';

const BASE_URL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? '' : process.env.PUBLIC_URL;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b00intro;
    this.dataBlockMobile = data.blocks.b00introMobile;
    this.state = {
      popupsState: {},
      openMenu: false,
    };
    this.onPopup = this.onPopup.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  onPopup(id, state) {
    this.setState(prev => ({ popupsState: { ...prev.popupsState, [id]: state } }));
  }

  toggleMenu(state) {
    this.setState(() => ({ openMenu: state }));
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.page}>
        <Hidden mdDown>
          <FixedColumn baseUrl={BASE_URL}></FixedColumn>
        </Hidden>
        <Menu toggleMenu={this.toggleMenu} openMenu={this.state.openMenu}></Menu>
        <Burger
          className={`${classes.burger} ${this.state.openMenu && classes.hidden}`}
          color="primary"
          onClick={() => this.toggleMenu(true)}
        />
        <Hidden lgUp>
          <Close
            className={`${classes.close} ${this.state.openMenu && classes.visible}`}
            onClick={() => this.toggleMenu(false)}
          />
        </Hidden>
        <div className={classes.content}>
          <Hidden mdDown>
            <div
              className={classes.bg}
              style={{
                backgroundImage: `url(${BASE_URL}/blocks/${this.dataBlock.bg})`,
                backgroundPosition: 'left calc(-5vw + 3px) center',
                backgroundSize: '80vw 100%',
                backgroundRepeat: 'no-repeat',
                position: 'fixed',
                zIndex: -1,
                width: '75vw',
                height: '100vh',
              }}
            ></div>
            <B00intro baseUrl={BASE_URL}></B00intro>
          </Hidden>
          <Hidden lgUp>
            <div
              className={classes.bg}
              style={{
                backgroundImage: `url(${BASE_URL}/blocks/${this.dataBlockMobile.bg})`,
                backgroundPosition: 'left calc(-5vw + 3px) center',
                backgroundSize: '80vw 100%',
                backgroundRepeat: 'no-repeat',
                position: 'fixed',
                zIndex: -1,
                width: '75vw',
                height: '100vh',
              }}
            ></div>
            <B00introMobile baseUrl={BASE_URL}></B00introMobile>
          </Hidden>
          <B01retirement></B01retirement>
          <B02crossedlooks
            onPopup={this.onPopup}
            popupsState={this.state.popupsState}
            baseUrl={BASE_URL}
          ></B02crossedlooks>
          <B03numbers></B03numbers>
          <B04facts baseUrl={BASE_URL}></B04facts>
          <B05missions onPopup={this.onPopup} popupsState={this.state.popupsState} baseUrl={BASE_URL}></B05missions>
          <B06subscriptions baseUrl={BASE_URL}></B06subscriptions>
          <B07info></B07info>
          <B08isr baseUrl={BASE_URL}></B08isr>
          <B09values onPopup={this.onPopup} popupsState={this.state.popupsState} baseUrl={BASE_URL}></B09values>
          <B10challenges onPopup={this.onPopup} popupsState={this.state.popupsState} baseUrl={BASE_URL}></B10challenges>
          <B11investor baseUrl={BASE_URL}></B11investor>
          <B12goals onPopup={this.onPopup} popupsState={this.state.popupsState}></B12goals>
          <B13ca baseUrl={BASE_URL}></B13ca>
          <Hidden mdDown>
            <Footer baseUrl={BASE_URL}></Footer>{' '}
          </Hidden>
          <Hidden lgUp>
            <FooterMobile baseUrl={BASE_URL}></FooterMobile>
          </Hidden>
        </div>
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);

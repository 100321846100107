import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import Stack from '@mui/material/Stack';
import Hidden from '@mui/material/Hidden';
import { withStyles } from '@mui/styles';
import { Typography } from '@mui/material/';
import Anchor from '../../components/Anchor';

import styles from './styles';
import data from '../../utils/data';

class B08isr extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b08isr;
    this.imgBase = `${this.props.baseUrl}/blocks/`;
  }

  render() {
    const { classes } = this.props;

    return (
      <Stack direction="row" className={classes.blockContainer}>
        <Anchor id="isr" />
        <Hidden mdDown>
          <img className={classes.banner} src={`${this.imgBase}/${this.dataBlock.banner}`} alt=""></img>
        </Hidden>
        <Stack direction="column" justifyContent="space-around" className={classes.content}>
          <Typography variant="h1" className={classes.articleTitle}>
            {parse(this.dataBlock.title)}
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            {parse(this.dataBlock.desc)}
          </Typography>
        </Stack>
      </Stack>
    );
  }
}

B08isr.propTypes = {
  baseUrl: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(B08isr);

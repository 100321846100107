export default theme => ({
  blockContainer: {
    position: 'relative',
  },
  articleTitle: {
    lineHeight: '45px !important',
    position: 'relative',
    color: theme.palette.green.main,
    backgroundColor: theme.palette.white.main,
    top: 0,
    left: 0,
    textAlign: 'center',
    padding: `${theme.spacing(6)}`,
    '& .title': {
      display: 'block',
      marginLeft: theme.spacing(18),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8rem !important',
      textIndent: 0,
      textAlign: 'center',
      lineHeight: '40px !important',
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: theme.spacing(4),
    },
  },
  imgSubtitle: {
    width: '100%',
    position: 'relative',
    fontSize: 0,
  },
  banner: {
    width: '100%',
    height: '100%',
  },
  subtitle: {
    color: theme.palette.white.main,
    width: '80%',
    maxWidth: 800,
    margin: 'auto !important',
    textAlign: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      fontSize: '1rem !important',
    },
  },
  articles: {
    backgroundColor: theme.palette.beige.main,
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
  },
  article: {
    padding: theme.spacing(6, 0),
    position: 'relative',
    textAlign: 'center',
    maxWidth: 200,
  },
  articleSubtitle: {
    textAlign: 'center',
    color: theme.palette.green.main,
  },
  btn: {
    padding: '0 !important',
    '& .icon-stroke': {
      stroke: theme.palette.green.main,
    },
  },
});

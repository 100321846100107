import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { withStyles } from '@mui/styles';
import { Stack, Typography } from '@mui/material/';
import Anchor from '../../components/Anchor';

import styles from './styles';
import data from '../../utils/data';

class B03numbers extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b03numbers;
  }

  render() {
    const { classes } = this.props;
    const title = parse(this.dataBlock.title);

    return (
      <Stack direction="column" alignItems="center" className={classes.blockContainer}>
        <Anchor id="numbers" />
        <Typography variant="h1" className={classes.articleTitle}>
          {title}
        </Typography>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={{ xs: 0, lg: 8 }}
          className={classes.content}
        >
          <Stack sx={{ flexDirection: { xs: 'column', lg: 'row' } }} justifyContent="space-between" spacing={4}>
            <Stack direction="column" alignItems="center" className={classes.number}>
              <Typography variant="h2" className={classes.numberTitle}>
                {parse(this.dataBlock.number1)}
              </Typography>
              <Typography variant="body2" className={classes.desc}>
                {parse(this.dataBlock.desc1)}
              </Typography>
            </Stack>
            <Stack direction="column" alignItems="center" className={classes.number}>
              <Typography variant="h2" className={classes.numberTitle}>
                {parse(this.dataBlock.number2)}
              </Typography>
              <Typography variant="body2" className={classes.desc}>
                {parse(this.dataBlock.desc2)}
              </Typography>
            </Stack>
            <Stack direction="column" alignItems="center" className={classes.number}>
              <Typography variant="h2" className={classes.numberTitle}>
                {parse(this.dataBlock.number3)}
              </Typography>
              <Typography variant="body2" className={classes.desc}>
                {parse(this.dataBlock.desc3)}
              </Typography>
            </Stack>
          </Stack>
          <Stack sx={{ flexDirection: { xs: 'column', lg: 'row' } }} justifyContent="space-between" spacing={4}>
            <Stack direction="column" alignItems="center" className={classes.number}>
              <Typography variant="h2" className={classes.numberTitle}>
                {parse(this.dataBlock.number4)}
              </Typography>
              <Typography variant="body2" className={classes.desc}>
                {parse(this.dataBlock.desc4)}
              </Typography>
            </Stack>
            <Stack direction="column" alignItems="center" className={classes.number}>
              <Typography variant="h2" className={classes.numberTitle}>
                {parse(this.dataBlock.number5)}
              </Typography>
              <Typography variant="body2" className={classes.desc}>
                {parse(this.dataBlock.desc5)}
              </Typography>
            </Stack>
            <Stack direction="column" alignItems="center" className={classes.number}>
              <Typography variant="body2" className={`${classes.desc} ${classes.prefix}`}>
                {parse(this.dataBlock.prefix6)}
              </Typography>
              <Typography variant="h2" className={classes.numberTitle}>
                {parse(this.dataBlock.number6)}
              </Typography>
              <Typography variant="body2" className={classes.desc}>
                {parse(this.dataBlock.desc6)}
              </Typography>
            </Stack>
            <Stack direction="column" alignItems="center" className={classes.number}>
              <Typography variant="h2" className={classes.numberTitle}>
                {parse(this.dataBlock.number7)}
              </Typography>
              <Typography variant="body2" className={classes.desc}>
                {parse(this.dataBlock.desc7)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }
}

B03numbers.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(B03numbers);

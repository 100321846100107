export default theme => ({
  blockContainer: {
    position: 'relative',
    height: '100vh',
    width: '75vw',
    display: 'flex',
  },
  center: {
    width: '50vw',
    borderRight: `solid 2px ${theme.palette.gold.main}`,
  },
  right: {
    '& img': {
      position: 'absolute',
      bottom: '50%',
      width: '100%',
      height: '50%',
    },
  },
  contentRight: {
    width: 'calc(25vw - 6vh - 1px)',
  },
  gutter: {
    width: '6vw',
    background: theme.palette.green.main,
  },
  top: {
    position: 'relative',
    minHeight: 25,
    maxHeight: '3vh',
    flex: 1,
    background: theme.palette.green.main,
    borderBottom: `solid 2px ${theme.palette.gold.main}`,
  },
  mainMargin: {
    position: 'relative',
    height: 50,
    background: theme.palette.green.main,
  },
  main: {
    position: 'relative',
    height: 'calc(50vw - 12vh)',
    overflow: 'hidden',
    flex: 1,
    '&.center': {
      borderTop: `solid 2px ${theme.palette.gold.main}`,
      borderBottom: `solid 2px ${theme.palette.gold.main}`,
    },
  },
  medium: {
    height: 50,
    flexBasis: 50,
    flexShrink: 0,
    background: theme.palette.green.main,
  },
  footer: {
    minHeight: 25,
    maxHeight: '3vh',
    flex: 1,
    background: theme.palette.green.main,
  },
  title: {
    color: theme.palette.gold.main,
    position: 'absolute',
    zIndex: 1,
    top: 'calc(50% + 20px)',
    right: 'calc(50% + 20px)',
  },
  line: {
    position: 'absolute',
    height: '100%',
    width: 2,
    top: 0,
    left: '50%',
    background: theme.palette.gold.main,
  },
  lineMiddle: {
    position: 'absolute',
    zIndex: 1,
    height: 2,
    width: '100%',
    top: '50%',
    left: 0,
    background: theme.palette.gold.main,
  },
  bgsquare: {
    position: 'absolute',
    top: '50%',
    left: 0,
    width: '100%',
    height: '50%',
    backgroundColor: theme.palette.green.main,
  },
  circle: {
    position: 'absolute',
    top: 0,
    left: '-100%',
    height: '100%',
    width: '200%',
    border: `solid 2px ${theme.palette.gold.main}`,
    borderRadius: '100%',
  },
  mainCircle: {
    position: 'absolute',
    zIndex: 1,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '100%',
    maxWidth: '100%',
    border: `solid 2px ${theme.palette.gold.main}`,
    borderRadius: '100%',
  },
  circleBottomLeft: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    maxHeight: '50%',
    maxWidth: '50%',
  },
  circleBottomRight: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    maxHeight: '50%',
    maxWidth: '50%',
  },
  circleTopLeft: {
    maxHeight: '100%',
    maxWidth: '50%',
  },
  circleTopRight: {
    maxHeight: '100%',
    maxWidth: '50%',
  },
  topRowMain: {
    height: '50%',
  },
  sideHorizontal: {
    backgroundColor: theme.palette.green.main,
    minWidth: 0,
    flex: 1,
  },
  side: {
    flex: 1,
    backgroundColor: theme.palette.green.main,
    minWidth: 0,
  },
  horizontal: {
    flexShrink: 0,
    flex: 1,
    height: '100%',
  },
});

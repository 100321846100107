import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { withStyles } from '@mui/styles';
import { Stack, Typography } from '@mui/material';

import styles from './styles';
import data from '../../utils/data';

class B00Intro extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b00intro;
    this.imgBase = `${this.props.baseUrl}/blocks/`;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.blockContainer}>
        <Stack direction="row" spacing={0}>
          <Stack direction="column" className={classes.center}>
            <div className={`${classes.top} center`}>
              <div className={classes.line}></div>
            </div>
            <div className={`${classes.mainMargin} center`}>
              <div className={classes.line}></div>
            </div>
            <div className={`${classes.main} center`}>
              <Typography variant="body1" className={classes.title}>
                {parse(this.dataBlock.title)}
              </Typography>
              <img className={classes.circleBottomLeft} src={`${this.imgBase}${this.dataBlock.bottomLeft}`} alt="" />
              <img className={classes.circleBottomRight} src={`${this.imgBase}${this.dataBlock.bottomRight}`} alt="" />
              <img className={classes.mainCircle} src={`${this.imgBase}${this.dataBlock.mainCircle}`} alt="" />
              <div className={classes.line}></div>
              <div className={classes.lineMiddle}></div>
              <Stack direction="column" spacing={0} className={classes.topRowMain}>
                <div className={classes.side}></div>
                <Stack direction="row" spacing={0} className={classes.horizontal}>
                  <div className={classes.sideHorizontal}></div>
                  <img className={classes.circleTopLeft} src={`${this.imgBase}${this.dataBlock.topLeft}`} alt="" />
                  <img className={classes.circleTopRight} src={`${this.imgBase}${this.dataBlock.topRight}`} alt="" />
                  <div className={classes.sideHorizontal}></div>
                </Stack>
              </Stack>
            </div>
            <div className={`${classes.medium} center`}></div>
            <div className={`${classes.footer} center`}></div>
          </Stack>
          <Stack direction="row" className={classes.right}>
            <Stack direction="column" className={classes.contentRight}>
              <div className={`${classes.top} right`}></div>
              <div className={`${classes.mainMargin} right`}></div>
              <div className={`${classes.main} right`}>
                <img src={`${this.imgBase}${this.dataBlock.topRightExtensible}`} alt="" />
                <div className={classes.lineMiddle}></div>
                <div className={classes.bgsquare}></div>
                <div className={classes.circle}></div>
              </div>
              <div className={`${classes.medium} right`}></div>
              <div className={`${classes.footer} right`}></div>
            </Stack>
            <Stack className={classes.gutter}>
              <div className={`${classes.top} gutter`}></div>
              <div className={`${classes.mainMargin} gutter`}></div>
              <div className={`${classes.main} gutter`}>
                <div className={classes.lineMiddle}></div>
              </div>
              <div className={`${classes.medium} gutter`}></div>
              <div className={`${classes.footer} gutter`}></div>
            </Stack>
          </Stack>
        </Stack>
      </div>
    );
  }
}

B00Intro.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
};

export default withStyles(styles)(B00Intro);

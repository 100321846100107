export default theme => ({
  blockContainer: {
    backgroundColor: theme.palette.white.main,
    position: 'relative',
    width: '75vw',
    [theme.breakpoints.down('md')]: {
      width: '100vw',
    },
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  title: {
    lineHeight: '45px !important',
    color: theme.palette.green.main,
    paddingBottom: theme.spacing(4),
    '& .title': {
      display: 'block',
      marginLeft: theme.spacing(18),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.3rem !important',
      textIndent: 0,
      textAlign: 'center',
      lineHeight: '40px !important',
      paddingLeft: 0,
      paddingBottom: 0,
    },
  },
  main: {
    padding: `0 calc(8vw) ${theme.spacing(3)} ${theme.spacing(3)}`,
    position: 'relative',
    height: 800,
    [theme.breakpoints.down('md')]: {
      height: 'initial',
    },
  },
  mainWrapper: {
    position: 'absolute',
    height: 'calc(100% - 48px)',
    width: 'calc(100% - 24px - 8vw)',
    top: theme.spacing(3),
    left: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      position: 'relative',
    },
  },
  subtitle: {
    color: theme.palette.gold.main,
    marginBottom: `${theme.spacing(2)} !important`,
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem !important',
    },
  },
  names: {
    color: theme.palette.green.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '0.7rem !important',
    },
  },
  photo: {
    width: 600,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  photoTop: {
    [theme.breakpoints.down('md')]: {
      width: 250,
    },
  },
  photoBottom: {
    [theme.breakpoints.down('md')]: {
      width: 250,
      position: 'relative',
    },
  },
  rightContent: {
    position: 'absolute',
    bottom: '50%',
    width: 320,
    left: 'calc(50% + 10px)',
    '& > *:not(img)': {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      bottom: 'initial',
      left: 'initial',
      position: 'relative',
      width: 200,
    },
  },
  leftContent: {
    position: 'absolute',
    left: 'calc(50% - 300px)',
    width: 290,
    top: '60%',
    [theme.breakpoints.down('md')]: {
      '& > *:not(img)': {
        marginLeft: theme.spacing(2),
      },
      top: 'initial',
      left: 'initial',
      position: 'relative',
      width: 200,
    },
  },
});

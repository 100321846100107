function loop(theme, res, list, type) {
  const suffixes = { main: '', light: 'light', ultralight: 'ultralight', dark: 'dark', ultradark: 'ultradark' };
  const suffixe = suffixes[type];
  list.forEach(key => {
    res[`${key}${suffixe}`] = {
      backgroundColor: theme.palette[key][type],
      color: theme.palette[key].contrastText,
    };
  });
}

export default theme => {
  const main = ['white', 'black', 'grey', 'beige', 'gold', 'green', 'lime'];
  const light = ['grey', 'gold', 'green', 'lime'];
  const dark = ['green', 'lime'];

  const res = {
    dialogPaper: {
      overflowX: 'hidden',
      backgroundColor: `${theme.palette.beige.main} !important`,
      padding: theme.spacing(5),
      width: '90% !important',
      paddingBottom: theme.spacing(8),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3),
        margin: theme.spacing(3),
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
      },
      '& > *': {
        position: 'relative',
      },
      '&:after': {
        content: '""',
        display: 'block',
        backgroundColor: theme.palette.gold.main,
        height: 1,
        width: '100%',
        position: 'absolute',
        bottom: theme.spacing(4),
        left: 0,
      },
    },
    closeIcon: {
      position: 'absolute !important',
      boxShadow: 'none !important',
      top: 20,
      right: 20,
      backgroundColor: 'transparent !important',
      border: `solid 2px ${theme.palette.gold.main} !important`,
      height: '30px !important',
      minHeight: '30px !important',
      width: '30px !important',
      transition: 'background 0.3s ease-in-out !important',
      '&:hover': {
        backgroundColor: `${theme.palette.white.main} !important`,
      },
      '& svg': {
        height: '20px !important',
        width: '20px !important',
        stroke: `${theme.palette.gold.main} !important`,
        fill: `${theme.palette.gold.main} !important`,
      },
      [theme.breakpoints.down('sm')]: {
        height: 40,
        width: 40,
        '& svg': {
          height: 25,
          width: 25,
        },
      },
      [theme.breakpoints.down('xs')]: {
        height: 32,
        width: 32,
        minHeight: 32,
        right: theme.spacing(4),
        '& svg': {
          height: 20,
          width: 20,
        },
      },
    },
    dialogContent: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: '0 !important',
        paddingBottom: `${theme.spacing(2)} !important`,
      },
      '& > *': {
        width: '100%',
        '&:first-child': {
          paddingRight: theme.spacing(2),
          [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
          },
        },
        '&:last-child': {
          paddingLeft: theme.spacing(5),
          [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(3),
          },
        },
      },
      '& .quote': {
        fontFamily: '"Bodoni Moda" !important',
        fontWeight: '700 !important',
        fontSize: '1.38rem !important',
        color: `${theme.palette.gold.main} !important`,
      },
    },
    title: {
      fontFamily: '"Commissioner", sans-serif !important',
      fontWeight: '100 !important',
      fontSize: '2.25rem !important',
      lineHeight: '1.25 !important',
      textTransform: 'none',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.8rem !important',
        padding: `${theme.spacing(4, 0)} !important`,
      },
    },
    desc: {
      color: `${theme.palette.grey.light} !important`,
      position: 'relative',
      paddingBottom: theme.spacing(1),
      '& .list': {
        display: 'block',
        margin: '1% 1%',
      },
    },
    columns: {
      columns: 3,
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
      [theme.breakpoints.down('md')]: {
        columns: 'initial',
      },
    },
  };

  loop(theme, res, main, 'main');
  loop(theme, res, light, 'light');
  loop(theme, res, dark, 'dark');

  return res;
};

import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { withStyles } from '@mui/styles';
import { Stack, IconButton, Typography } from '@mui/material/';
import { ReactComponent as Add } from '../../res/add.svg';
import PopupRounded from '../../popups/PopupRounded';
import styles from './styles';
import data from '../../utils/data';

class B09values extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b09values;
    this.dataPopups = data.popups;
    this.imgBase = `${this.props.baseUrl}/blocks/`;
  }

  renderValue(value, idx) {
    const { classes, onPopup } = this.props;

    return (
      <Stack direction="column" key={idx} className={classes.value}>
        <div className={classes.imgWrapper}>
          <img className={classes.image} src={`${this.imgBase}/${value.image}`} alt=""></img>
          <IconButton onClick={() => onPopup(value.popupTrigger, true)} className={classes.btn}>
            <Add />
          </IconButton>
        </div>
        <Typography variant="subtitle2" className={classes.desc}>
          {parse(value.desc)}
        </Typography>
      </Stack>
    );
  }

  render() {
    const { classes, onPopup, popupsState } = this.props;

    return (
      <Stack direction="column" alignItems="center" className={classes.blockContainer}>
        <Typography variant="h2" className={classes.title}>
          {parse(this.dataBlock.title)}
        </Typography>
        <Stack direction="row" justifyContent="space-around" alignItems="flex-start" className={classes.content}>
          {this.dataBlock.values.map((value, idx) => this.renderValue(value, idx))}
        </Stack>
        <PopupRounded
          color="lime"
          colorText="green"
          colorTitle="green"
          popupData={this.dataPopups.popupValueHR}
          open={!!popupsState.popupValueHR}
          onClose={() => onPopup('popupValueHR', false)}
        ></PopupRounded>
        <PopupRounded
          color="lime"
          colorText="green"
          colorTitle="green"
          popupData={this.dataPopups.popupValueProgress}
          open={!!popupsState.popupValueProgress}
          onClose={() => onPopup('popupValueProgress', false)}
        ></PopupRounded>
        <PopupRounded
          color="lime"
          colorText="green"
          colorTitle="green"
          popupData={this.dataPopups.popupValueDemocracy}
          open={!!popupsState.popupValueDemocracy}
          onClose={() => onPopup('popupValueDemocracy', false)}
        ></PopupRounded>
        <PopupRounded
          color="lime"
          colorText="green"
          colorTitle="green"
          popupData={this.dataPopups.popupValueEnvironment}
          open={!!popupsState.popupValueEnvironment}
          onClose={() => onPopup('popupValueEnvironment', false)}
        ></PopupRounded>
        <PopupRounded
          color="lime"
          colorText="green"
          colorTitle="green"
          popupData={this.dataPopups.popupValueLeadership}
          open={!!popupsState.popupValueLeadership}
          onClose={() => onPopup('popupValueLeadership', false)}
        ></PopupRounded>
      </Stack>
    );
  }
}

B09values.propTypes = {
  baseUrl: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onPopup: PropTypes.func,
  popupsState: PropTypes.object,
};

export default withStyles(styles)(B09values);

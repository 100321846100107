import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import { Stack, Typography } from '@mui/material';

import styles from './styles';
import data from '../../utils/data';

class B01retirement extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b01retirement;
  }

  render() {
    const { classes } = this.props;
    return (
      <Stack direction="row" className={classes.blockContainer}>
        <div className={classes.leftBlock}>
          <Typography variant="body2" className={classes.mainTitle}>
            {this.dataBlock.title}
          </Typography>
          <div className={classes.descContainer}>
            <Typography variant="body2" className={classes.desc}>
              {this.dataBlock.desc}
            </Typography>
          </div>
        </div>
      </Stack>
    );
  }
}

B01retirement.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(B01retirement);

import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { withStyles } from '@mui/styles';
import { Stack, Typography } from '@mui/material';

import styles from './styles';
import data from '../../utils/data';

class B00IntroMobile extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b00introMobile;
    this.imgBase = `${this.props.baseUrl}/blocks/`;
    this.imgBaseCommon = `${this.props.baseUrl}/common/`;
  }

  render() {
    const { classes } = this.props;
    return (
      <Stack direction="column" className={classes.blockContainer}>
        <Stack direction="column" className={classes.header}>
          <Stack direction="row" className={classes.rowTopMargin} spacing={0}>
            <div className={`${classes.left}`}></div>
            <div className={`${classes.insideLeft}`}></div>
            <div className={`${classes.main} main`}></div>
            <div className={`${classes.insideRight}`}></div>
            <div className={`${classes.right}`}></div>
          </Stack>
          <Stack direction="row" className={classes.rowTop} spacing={0}>
            <div className={`${classes.left}`}></div>
            <div className={`${classes.insideLeft}`}>
              <img className={classes.logo} src={`${this.imgBaseCommon}${this.dataBlock.logo}`} alt="" />
            </div>
            <div className={`${classes.main} main`}>
              <div className={classes.circle}></div>
              <Typography variant="body2" className={classes.title}>
                {parse(this.dataBlock.title)}
              </Typography>
            </div>
            <div className={`${classes.insideRight}`}></div>
            <div className={`${classes.right}`}></div>
          </Stack>
        </Stack>
        <Stack direction="column" className={classes.headerFiller}></Stack>

        <Stack direction="row" className={classes.rowMain} spacing={0}>
          <div className={`${classes.left}`}></div>
          <div className={`${classes.insideLeft}`}></div>
          <div className={`${classes.main} main`}>
            <img className={classes.mainCircle} src={`${this.imgBase}${this.dataBlock.mainCircle}`} alt="" />
            <img
              className={`${classes.circleTopLeft} ${classes.halfCircle} halfCircle`}
              src={`${this.imgBase}${this.dataBlock.topLeft}`}
              alt=""
            />
            <img
              className={`${classes.circleBottomLeft} ${classes.halfCircle} halfCircle`}
              src={`${this.imgBase}${this.dataBlock.bottomLeft}`}
              alt=""
            />
            <div className={classes.lineMiddle}></div>
            <div className={classes.line}></div>
            <Stack direction="column" spacing={0} className={classes.topRowMain}>
              <Stack direction="column" spacing={0} className={classes.specialColumn}>
                <div className={classes.side}></div>
                <img
                  className={`${classes.circleTopRight} ${classes.halfCircle} halfCircle`}
                  src={`${this.imgBase}${this.dataBlock.topRight}`}
                  alt=""
                />
                <img
                  className={`${classes.circleBottomRight} ${classes.halfCircle} halfCircle`}
                  src={`${this.imgBase}${this.dataBlock.bottomRight}`}
                  alt=""
                />
                <div className={classes.side}></div>
              </Stack>
            </Stack>
          </div>
          <div className={`${classes.insideRight}`}></div>
          <div className={`${classes.right}`}></div>
        </Stack>
        <Stack direction="row" className={classes.rowBottom} spacing={0}>
          <div className={`${classes.left}`}></div>
          <div className={`${classes.insideLeft}`}></div>
          <div className={`${classes.main} main`}>
            <div className={`${classes.circle} ${classes.circleBottom}`}></div>
            <div className={classes.bgsquare}></div>
            <img
              className={`${classes.rightBottomCircleBelow} ${classes.halfCircle} halfCircle`}
              src={`${this.imgBase}${this.dataBlock.bottomRightExtensible}`}
              alt=""
            />
            <div className={classes.line}></div>
          </div>
          <div className={`${classes.insideRight}`}></div>
          <div className={`${classes.right}`}></div>
        </Stack>
        <Stack direction="row" className={classes.rowBottomMargin} spacing={0}>
          <div className={`${classes.left}`}></div>
          <div className={`${classes.insideLeft}`}></div>
          <div className={`${classes.main} main`}>
            <div className={classes.line}></div>
          </div>
          <div className={`${classes.insideRight}`}></div>
          <div className={`${classes.right}`}></div>
        </Stack>
      </Stack>
    );
  }
}

B00IntroMobile.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
};

export default withStyles(styles)(B00IntroMobile);

export default theme => ({
  blockContainer: {
    position: 'fixed',
    zIndex: 101,
    top: 0,
    right: '-50%',
    height: '100vh',
    width: '50vw',
    backgroundColor: theme.palette.green.main,
    borderLeft: `solid 2px ${theme.palette.gold.main}`,
    transition: 'right 0.3s ease-in-out',
    paddingLeft: theme.spacing(4),
    paddingTop: 'calc(3vh + 30px)',
    paddingBottom: 'calc(3vh + 30px)',
    [theme.breakpoints.down('md')]: {
      zIndex: 30,
      width: '100%',
      right: 'initial',
      top: '100%',
      maxHeight: '80%',
      transition: 'top 0.3s ease-in-out',
      borderLeft: 'initial',
      paddingTop: 10,
      paddingBottom: 110,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  open: {
    right: 0,
    [theme.breakpoints.down('md')]: {
      top: '20%',
    },
  },
  overlay: {
    position: 'fixed',
    zIndex: 100,
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    opacity: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    transition: 'opacity 0.3s ease-in-out',
    pointerEvents: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  showOverlay: {
    opacity: 1,
    pointerEvents: 'all',
    cursor: 'auto',
  },
  close: {
    position: 'absolute',
    top: 'calc(3vh + 30px)',
    right: 'calc(6vh + 30px)',
    zIndex: 75,
    width: 35,
    height: 35,
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('md')]: {
      width: 25,
      height: 25,
      top: theme.spacing(4),
      right: theme.spacing(4),
    },
  },
  btn: {
    position: 'absolute !important',
    maxWidth: '33%',
    [theme.breakpoints.down('md')]: {
      padding: '0 !important',
    },
  },
  btnLeft: {
    bottom: 20,
    left: 20,
    [theme.breakpoints.down('md')]: {
      bottom: theme.spacing(4),
      right: theme.spacing(4),
    },
  },
  btnRight: {
    bottom: 20,
    right: 20,
    [theme.breakpoints.down('md')]: {
      bottom: theme.spacing(4),
      right: theme.spacing(4),
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.lime.main,
    maxWidth: '50%',
    width: 'fit-content',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      maxWidth: '80%',
      margin: 'auto !important',
      '& > *': {
        textAlign: 'center',
      },
    },
  },
  linkText: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem !important',
    },
  },
});

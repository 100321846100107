function loop(theme, res, list, type) {
  const suffixes = { main: '', light: 'light', dark: 'dark' };
  const suffixe = suffixes[type];
  list.forEach(key => {
    res[`${key}${suffixe}`] = {
      color: `${theme.palette[key][type]} !important`,
      '& svg .icon-fill': {
        fill: theme.palette[key][type],
      },
      '& svg .icon-stroke': {
        stroke: theme.palette[key][type],
      },
      '&:hover': {
        textDecorationLine: 'underline',
      },
    };
  });
}

export default theme => {
  const main = ['white', 'black', 'grey', 'beige', 'gold', 'green', 'lime'];
  const light = ['grey', 'gold', 'green', 'lime'];
  const dark = ['green', 'lime'];

  const res = {
    button: {
      width: 'fit-content',
      marginLeft: 'auto',
      '&.iconCenter': {
        alignItems: 'center',
      },
      '&.iconTop': {
        alignItems: 'flex-start',
      },
      '&.iconRight': {
        justifyContent: 'end',
        flexDirection: 'row-reverse',
        alignItems: 'flex-start',
        '& > span': {
          marginLeft: `${theme.spacing(1)} !important`,
          marginRight: '0 !important',
        },
      },
      '&.iconAbove': {
        alignItems: 'flex-start',
        '& > span': {
          flexDirection: 'column',
          '& > span': {
            marginBottom: theme.spacing(1),
          },
        },
      },
    },
    label: {
      width: 'fit-content',
      textAlign: 'right',
      fontSize: '0.5625rem !important',
      fontWeight: '300 !important',
    },
  };

  loop(theme, res, main, 'main');
  loop(theme, res, light, 'light');
  loop(theme, res, dark, 'dark');

  return res;
};

import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import Stack from '@mui/material/Stack';
import { withStyles } from '@mui/styles';
import { IconButton, Typography } from '@mui/material/';
import { ReactComponent as Add } from '../../res/add.svg';
import PopupRounded from '../../popups/PopupRounded';
import styles from './styles';
import data from '../../utils/data';

class B12goals extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b12goals;
    this.dataPopups = data.popups;
  }

  renderDiagram() {
    const { classes, onPopup } = this.props;
    return (
      <Stack direction="column" justifyContent="center" alignItems="center" className={classes.diagram}>
        <div className={classes.square}>
          <div className={classes.circle}></div>
          <div className={`${classes.goal} ${classes.goal1}`}>
            <Typography variant="subtitle2" className={classes.desc}>
              {parse(this.dataBlock.goal1)}
            </Typography>
            <IconButton onClick={() => onPopup(this.dataBlock.goal1popupTrigger, true)} className={classes.btn}>
              <Add />
            </IconButton>
          </div>
          <div className={`${classes.goal} ${classes.goal2}`}>
            <Typography variant="subtitle2" className={classes.desc}>
              {parse(this.dataBlock.goal2)}
            </Typography>
            <IconButton onClick={() => onPopup(this.dataBlock.goal2popupTrigger, true)} className={classes.btn}>
              <Add />
            </IconButton>
          </div>
          <div className={`${classes.goal} ${classes.goal3}`}>
            <Typography variant="subtitle2" className={classes.desc}>
              {parse(this.dataBlock.goal3)}
            </Typography>
            <IconButton
              onClick={() => onPopup(this.dataBlock.goal3popupTrigger, true)}
              className={`${classes.btn} btn`}
            >
              <Add />
            </IconButton>
          </div>
          <div className={`${classes.goal} ${classes.goal4}`}>
            <Typography variant="subtitle2" className={classes.desc}>
              {parse(this.dataBlock.goal4)}
            </Typography>
            <IconButton onClick={() => onPopup(this.dataBlock.goal4popupTrigger, true)} className={classes.btn}>
              <Add />
            </IconButton>
          </div>
          <div className={`${classes.goal} ${classes.goal5}`}>
            <Typography variant="caption" className={`${classes.desc} ${classes.centerDesc}`}>
              {parse(this.dataBlock.goal5)}
            </Typography>
          </div>
        </div>
      </Stack>
    );
  }

  render() {
    const { classes, popupsState, onPopup } = this.props;
    return (
      <Stack direction="column" spacing={4} className={classes.blockContainer}>
        <Typography variant="body1" sx={{ pt: '32px', pb: '32px' }} className={classes.title}>
          {parse(this.dataBlock.title)}
        </Typography>
        {this.renderDiagram()}
        <Typography variant="body2" sx={{ pt: '32px', pb: '32px' }} className={classes.subtitle}>
          {parse(this.dataBlock.subtitle)}
        </Typography>
        <PopupRounded
          color="green"
          colorText="white"
          colorTitle="gold"
          centerText={false}
          popupData={this.dataPopups.popupGoalEngagement}
          open={!!popupsState.popupGoalEngagement}
          onClose={() => onPopup('popupGoalEngagement', false)}
        ></PopupRounded>
        <PopupRounded
          color="beige"
          colorText="green"
          colorTitle="limedark"
          centerText={false}
          popupData={this.dataPopups.popupGoalSectors}
          open={!!popupsState.popupGoalSectors}
          onClose={() => onPopup('popupGoalSectors', false)}
        ></PopupRounded>
        <PopupRounded
          color="gold"
          colorText="white"
          colorTitle="white"
          centerText={false}
          popupData={this.dataPopups.popupGoalEmission}
          open={!!popupsState.popupGoalEmission}
          onClose={() => onPopup('popupGoalEmission', false)}
        ></PopupRounded>
        <PopupRounded
          color="limedark"
          colorText="green"
          colorTitle="green"
          centerText={false}
          popupData={this.dataPopups.popupGoalFinance}
          open={!!popupsState.popupGoalFinance}
          onClose={() => onPopup('popupGoalFinance', false)}
        ></PopupRounded>
      </Stack>
    );
  }
}

B12goals.propTypes = {
  classes: PropTypes.object.isRequired,
  onPopup: PropTypes.func,
  popupsState: PropTypes.object,
};

export default withStyles(styles)(B12goals);

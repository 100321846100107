export default theme => ({
  column: {
    position: 'fixed',
    zIndex: 100,
    top: 0,
    left: 0,
    background: theme.palette.green.main,
    width: '25vw',
    height: '100vh',
    display: 'flex',
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.green.main,
    borderRight: `solid 2px ${theme.palette.gold.main}`,
    width: '6vh',
    maxWidth: '3vw',
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.green.main,
    color: theme.palette.gold.main,
    borderRight: `solid 2px ${theme.palette.gold.main}`,
    flexGrow: 1,
    flexShrink: 1,
  },
  top: {
    position: 'relative',
    minHeight: 25,
    maxHeight: '3vh',
    flex: 1,
    borderBottom: `solid 2px ${theme.palette.gold.main}`,
  },
  mainMargin: {
    position: 'relative',
    height: 50,
  },
  logo: {
    position: 'absolute',
    top: 1,
    left: 1,
    width: 100,
    height: 67,
  },
  main: {
    position: 'relative',
    height: 'calc(50vw - 12vh)',
    overflow: 'hidden',
    flex: 1,
  },
  circle: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '200%',
    border: `solid 2px ${theme.palette.gold.main}`,
    borderRadius: '100%',
  },
  textContent: {
    textAlign: 'right',
    width: '40%',
    right: '55%',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    alignItems: 'flex-end !important',
    '& a': {
      textDecoration: 'none',
    },
  },
  medium: {
    height: 50,
    flexBasis: 50,
    flexShrink: 0,
    borderTop: `solid 2px ${theme.palette.gold.main}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footer: {
    minHeight: 25,
    maxHeight: '3vh',
    flex: 1,
    background: theme.palette.green.main,
    borderTop: `solid 2px ${theme.palette.gold.main}`,
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { withStyles } from '@mui/styles';
import { Dialog, DialogContent, DialogTitle, Fab, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './styles';

class PopupSquared extends React.Component {
  renderSectionColumn(content) {
    const { popupData, classes } = this.props;
    return (
      <div className={`${popupData.desc2 ? '' : classes.columns} columns`}>
        {typeof content === 'string' && (
          <Typography key="content" variant="body2" className={classes.desc}>
            {parse(content)}
          </Typography>
        )}
        {Array.isArray(content) &&
          content.map((c, idx) => (
            <Typography key={idx} variant="body2" className={classes.desc}>
              {parse(c)}
            </Typography>
          ))}
      </div>
    );
  }

  render() {
    const { popupData, classes, color, open, onClose } = this.props;
    return (
      <Dialog
        maxWidth="md"
        fullWidth
        scroll="body"
        open={open || false}
        onClose={onClose}
        PaperProps={{
          square: true,
          className: `${classes.dialogPaper} ${classes[color]}`,
        }}
      >
        <Fab color="primary" onClick={onClose} className={classes.closeIcon}>
          <CloseIcon fontSize="large" />
        </Fab>
        <DialogTitle className={classes.title}>{parse(popupData.title)}</DialogTitle>
        <DialogContent className={`${classes.dialogContent}`}>
          {this.renderSectionColumn(popupData.desc1)}
          {popupData.desc2 && this.renderSectionColumn(popupData.desc2)}
          {popupData.desc3 && this.renderSectionColumn(popupData.desc3)}
        </DialogContent>
      </Dialog>
    );
  }
}

PopupSquared.propTypes = {
  popupData: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  title: PropTypes.node,
  color: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

PopupSquared.defaultProps = {
  color: 'black',
};

export default withStyles(styles)(PopupSquared);
